export const i18nRu = {
    Сводка: 'Сводка',
    Аналитика: 'Аналитика',
    'Список клиентов': 'Список клиентов',
    Сегменты: 'Сегменты',
    'Покупки клиентов': 'Покупки клиентов',
    'Автоматические рассылки': 'Автоматические рассылки',
    Рассылка: 'Рассылка',
    'Автоматическая рассылка': 'Автоматическая рассылка',
    'Ручная рассылка': 'Ручная рассылка',
    'Ручные рассылки': 'Ручные рассылки',
    'Список выгрузок': 'Список выгрузок',
    Промокоды: 'Промокоды',
    'Приведи друга': 'Приведи друга',
    Промокод: 'Промокод',
    Код: 'Код',
    Акции: 'Акции',
    Акция: 'Акция',
    Возврат: 'Возврат',
    Цепочки: 'Цепочки',
    Записаться: 'Записаться',
    'Пример цепочки': 'Пример цепочки',
    'Роль добавлена': 'Роль добавлена',
    'Роль редактирована': 'Роль редактирована',
    'Просмотр роли': 'Просмотр роли',
    'Уйти без сохранения?': 'Уйти без сохранения?',
    'Недоступно для вашей роли': 'Недоступно для вашей роли',
    Уйти: 'Уйти',
    'Если введенные данные важны, сохраните их перед уходом с формы': 'Если введенные данные важны, сохраните их перед уходом с формы',
    'Сбросить изменения': 'Сбросить изменения',
    'Остаться на форме': 'Остаться на форме',
    'Есть идеи?': 'Есть идеи?',
    'Если у вас есть пожелания к цепочкам или опыт работы с автоматизациями — самое время записаться на созвон:': 'Если у вас есть пожелания к цепочкам или опыт работы с автоматизациями — самое время записаться на созвон:',
    'все триггеры и условия в новом формате': 'все триггеры и условия в <strong> новом формате </strong>',
    'новые связки между рассылками — паузы и условия': '<strong>новые связки</strong> между рассылками — паузы и условия',
    'полную картину всех развилок для кампании': 'полную картину <strong>всех развилок</strong> для кампании',
    'возможность работы над несколькими рассылками не выходя из редактора': 'возможность работы над несколькими рассылками <strong>не выходя из редактора</strong>',
    'Что дадут цепочки?': 'Что дадут цепочки?',
    'Сумма возврата': 'Сумма возврата',
    'Создавать авторассылки будет еще проще!': 'Создавать авторассылки\nбудет еще проще!',
    'за все покупки': 'за все покупки',
    'Сумма до скидки': 'Сумма до скидки',
    'нет характеристик': 'нет характеристик',
    'Сумма скидок': 'Сумма скидок',
    'Оплачено бонусами': 'Оплачено бонусами',
    'Подарочные карты': 'Подарочные карты',
    'Подарочная карта': 'Подарочная карта',
    'Список выпущенных карт': 'Список выпущенных карт',
    Пользователи: 'Пользователи',
    'Точки продаж': 'Точки продаж',
    'Импорт данных': 'Импорт данных',
    'Новый импорт': 'Новый импорт',
    Расходы: 'Расходы',
    Счета: 'Счета',
    'Бонусы успешно начислены': 'Бонусы успешно начислены',
    'Бонусы успешно списаны': 'Бонусы успешно списаны',
    'Сегмент сохранен': 'Сегмент сохранен',
    'Изменения сохранены': 'Изменения сохранены',
    'Клиент добавлен': 'Клиент добавлен',
    'Клиент удалён': 'Клиент удалён',
    'Номер телефона': 'Номер телефона',
    '№ карты': '№ карты',
    'Сделан возврат': 'Сделан возврат',
    Да: 'Да',
    'Перезаписать фильтры сегмента {{segmentName}}?': 'Перезаписать фильтры сегмента\n{{segmentName}}?',
    Отмена: 'Отмена',
    Перезаписать: 'Перезаписать',
    'Удалить клиента?': 'Удалить клиента?',
    Удалить: 'Удалить',
    'Прибыль по ПЛ': 'Прибыль по ПЛ',
    'Выдано карт': 'Выдано карт',
    'Покупки с картами': 'Покупки с картами',
    'Расходы по ПЛ': 'Расходы на ПЛ',
    'Не потрачено': 'Не потрачено',
    Использовано: 'Использовано',
    Сгорело: 'Сгорело',
    'Выйти без сохранения?': 'Выйти без сохранения?',
    'Установить карту Wallet': 'Установить карту Wallet',
    отписаться: 'отписаться',
    Выйти: 'Выйти',
    'Удалить подарочную карту?': 'Удалить подарочную карту?',
    'Подарочная карта удалена': 'Подарочная карта удалена',
    'Подарочная карта изменена': 'Подарочная карта изменена',
    'Подарочная карта создана': 'Подарочная карта создана',
    'Карта успешно активирована': 'Карта успешно активирована',
    'Разблокировать подарочную карту?': 'Разблокировать подарочную карту?',
    Разблокировать: 'Разблокировать',
    'Карта успешно разблокирована': 'Карта успешно разблокирована',
    'Заблокировать подарочную карту?': 'Заблокировать подарочную карту?',
    Заблокировать: 'Заблокировать',
    'Карта успешно заблокирована': 'Карта успешно заблокирована',
    'Импорт удалён': 'Импорт удалён',
    'Загрузка из буфера обмена': 'Загрузка из буфера обмена',
    'Загрузка из': 'Загрузка из',
    'Импорт создан': 'Импорт создан',
    'Удалить импорт?': 'Удалить импорт?',
    'Счет выставлен': 'Счет выставлен',
    Копия: 'Копия',
    OP_OR: 'или',
    OP_NOT: 'не',
    OP_AND: 'и',
    'Рассылка создана': 'Рассылка создана',
    'Рассылка сохранена': 'Рассылка сохранена',
    'Не выбраны каналы коммуникации': 'Не выбраны каналы коммуникации',
    'Вы действительно хотите очистить дизайн?': 'Вы действительно хотите очистить дизайн?',
    'Сообщение отправлено': 'Сообщение отправлено',
    'У вас остался несохраненный дизайн': 'У вас остался несохраненный дизайн',
    'Хотите его восстановить?': 'Хотите его восстановить?',
    Нет: 'Нет',
    'Содержимое письма будет удалено.': 'Содержимое письма будет удалено.',
    Очистить: 'Очистить',
    'совершение покупки': 'совершение покупки',
    Ручные: 'Ручные',
    Авторассылки: 'Авторассылки',
    'Попадание в сегмент': 'Попадание в сегмент',
    'Покидание сегмента': 'Покидание сегмента',
    'Начисление бонусов за покупку': 'Начисление бонусов за покупку',
    'Возврат товара': 'Возврат товара',
    'День рождения': 'День рождения',
    'Сгорание бонусов': 'Сгорание бонусов',
    'Совершение покупки': 'Совершение покупки',
    'День рождения ребёнка': 'День рождения ребёнка',
    'Брошенная корзина': 'Брошенная корзина',
    'Просмотр товара или категории': 'Просмотр товара или категории',
    'Цена на товар в корзине снижена': 'Цена на товар в корзине снижена',
    'Цена на товар в избранном снижена': 'Цена на товар в избранном снижена',
    'Без подсчета': 'Без подсчета',
    'Подтверждение подписки на рассылку': 'Подтверждение подписки на рассылку',
    'Дозаполнение хотя бы 1 поля анкеты': 'Дозаполнение хотя бы 1 поля анкеты',
    '100% доставка рассылки': '100% доставка рассылки',
    'Прохождение online-опроса': 'Прохождение online-опроса',
    'Установка карты Wallet': 'Установка карты Wallet',
    'Переход по ссылке': 'Переход по ссылке',
    'Канал включен': 'Канал включен',
    Выключен: 'Выключен',
    Покупка: 'Покупка',
    'Сумма покупки': 'Сумма покупки',
    'Место покупки': 'Место покупки',
    Наименование: 'Наименование',
    Товар: 'Товар',
    Артикул: 'Артикул',
    'Товар {{ num }}': 'Товар {{ num }}',
    Условия: 'Условия',
    Количество: 'Количество',
    Сумма: 'Сумма',
    'Акция удалена': 'Акция удалена',
    'Акция изменена': 'Акция изменена',
    'Акция создана': 'Акция создана',
    TOOLTIP_ARBITRATION_TYPE: 'Принцип, по которому эта акция комбинируется с другими.<br /><br /><b>Единственная в чеке</b> - если применилась такая акция, к чеку не применится никакая другая.<br /><br /><b>Вытесняет другие</b> - если к строке чека применилась такая акция, акция с типом Уступает другим к этой строке не применятся.<br /><br /><b>Уступает другим</b> - применяется на те строки чека, на которые не применена акция Вытесняет другие.<br /><br /><b>Выбирается, если выгоднее</b> - применяется к строке, только если дает большую скидку (или большее количество бонусов), чем уже примененная к этой строке акция.<br /><br /><b>Суммируется</b> - применяется к строке, даже если к той же строке уже применены другие акции.',
    'Удалить акцию?': 'Удалить акцию?',
    'Промокод удалён': 'Промокод удалён',
    'Код изменён': 'Код изменён',
    'Код создан': 'Код создан',
    'Промокод изменён': 'Промокод изменён',
    'Промокод создан': 'Промокод создан',
    'Удалить код?': 'Удалить код?',
    'Удалить промокод?': 'Удалить промокод?',
    'Код удалён': 'Код удалён',
    'Покупка добавлена': 'Покупка добавлена',
    'Покупка изменена': 'Покупка изменена',
    'Покупка удалена': 'Покупка удалена',
    'Удалить покупку?': 'Удалить покупку?',
    'Название отчета': 'Название отчета',
    Переименовать: 'Переименовать',
    'Новый отчет': 'Новый отчет',
    'Отчет сохранен': 'Отчет сохранен',
    'Отчет удален': 'Отчет удален',
    'Сохранить изменения в отчете {{name}}?': 'Сохранить изменения в отчете\n{{name}}?',
    Остаться: 'Остаться',
    'Не сохранять': 'Не сохранять',
    Сохранить: 'Сохранить',
    'Удалить «{{name}}»?': 'Удалить «{{name}}»?',
    'Сегмент удален': 'Сегмент удален',
    'Клиенты удалены': 'Клиенты удалены',
    'Удаление сегмента «{{segmentName}}»': 'Удаление сегмента «{{segmentName}}»',
    'Также будет удалена статистика и отключены рассылки, назначенные для этого сегмента.': 'Также будет удалена статистика и отключены рассылки, назначенные для этого сегмента.',
    'Безвозвратное удаление клиентов': 'Безвозвратное удаление клиентов',
    'Клиенты сегмента «{{segmentName}}» будут навсегда удалены из системы': 'Клиенты сегмента «{{segmentName}}» будут навсегда удалены из системы',
    'Точка продаж сохранена': 'Точка продаж сохранена',
    'Точка продаж создана': 'Точка продаж создана',
    'Точка продаж удалена': 'Точка продаж удалена',
    'Удалить точку продаж?': 'Удалить точку продаж?',
    'Пользователь удален': 'Пользователь удален',
    'Пользователь сохранен': 'Пользователь сохранен',
    'Удалить пользователя?': 'Удалить пользователя?',
    'Система лояльности': 'Система лояльности',
    'Ошибка при обращении к серверу': 'Ошибка при обращении к серверу',
    'В запросе содержатся некорректные данные': 'В запросе содержатся некорректные данные',
    'Время действия сессии истекло, пожалуйста, войдите снова': 'Время действия сессии истекло, пожалуйста, войдите снова',
    'Недостаточно прав для доступа к ресурсу': 'Недостаточно прав для доступа к ресурсу',
    'Ошибка при обращении к серверу, повторите попытку позже': 'Ошибка при обращении к серверу, повторите попытку позже',
    Клиенты: 'Клиенты',
    Имя: 'Имя',
    Фамилия: 'Фамилия',
    Отчество: 'Отчество',
    Пол: 'Пол',
    Возраст: 'Возраст',
    Дети: 'Дети',
    'Имя ребёнка': 'Имя ребёнка',
    'Возраст ребёнка': 'Возраст ребёнка',
    'Пол ребёнка': 'Пол ребёнка',
    'Дополнительные поля': 'Дополнительные поля',
    'Каналы коммуникации': 'Каналы коммуникации',
    Подписки: 'Подписки',
    Телефон: 'Телефон',
    'Телефон указан': 'Телефон указан',
    'Поиск по телефонам': 'Поиск по телефонам',
    Email: 'Email',
    'Email указан': 'Email указан',
    'Поиск по email': 'Поиск по email',
    'Подтвердил подписку': 'Подтвердил подписку',
    'Ошибка в контактах': 'Ошибка в контактах',
    'Программа лояльности': 'Программа лояльности',
    'Статус участия': 'Статус участия',
    'Вступление в программу': 'Вступление в программу',
    'Дата вступления': 'Дата вступления',
    'Дней в программе': 'Дней в программе',
    'Место вступления': 'Место вступления',
    'Кто добавил': 'Кто добавил',
    'Поиск по номерам карт': 'Поиск по номерам карт',
    'Карты Wallet': 'Карты Wallet',
    Уровень: 'Уровень',
    Бонусы: 'Бонусы',
    'Бонусов на счету': 'Бонусов на счету',
    'Бонусов использовано': 'Бонусов использовано',
    'Бонусов сгорело': 'Бонусов сгорело',
    'Дата сгорания бонусов': 'Дата сгорания бонусов',
    Покупки: 'Покупки',
    'Количество покупок': 'Количество покупок',
    'Количество позиций': 'Количество позиций',
    'Сумма покупок': 'Сумма покупок',
    'Средний чек': 'Средний чек',
    'Максимальный чек': 'Максимальный чек',
    'Дней между покупками': 'Дней между покупками',
    'Дата совершения покупок': 'Дата совершения покупок',
    'Дата покупки': 'Дата покупки',
    'Покупка дней назад': 'Покупка дней назад',
    'Дней без покупок': 'Дней без покупок',
    'Дата п/покупки': 'Дата п/покупки',
    'Где покупал ранее': 'Где покупал ранее',
    Продавец: 'Продавец',
    Кассир: 'Кассир',
    'введите имя кассира': 'введите имя кассира',
    'Что покупал ранее': 'Что покупал ранее',
    'нет акций': 'нет акций',
    Возвраты: 'Возвраты',
    'Количество возвратов': 'Количество возвратов',
    'Сумма возвратов': 'Сумма возвратов',
    'Дата возврата': 'Дата возврата',
    'Возврат дней назад': 'Возврат дней назад',
    'Дней без возвратов': 'Дней без возвратов',
    'Дата п/возврата': 'Дата п/возврата',
    'Где возвращал': 'Где возвращал',
    'Место возврата': 'Место возврата',
    'Кто принял': 'Кто принял',
    'Что возвращал': 'Что возвращал',
    Категория: 'Категория',
    'Категория в-та': 'Категория в-та',
    'Артикул в-та': 'Артикул в-та',
    'Наименование в-та': 'Наименование в-та',
    Цена: 'Цена',
    'Наценка, %': 'Наценка, %',
    'Переоценка, %': 'Переоценка, %',
    'Скидка кассы, %': 'Скидка кассы, %',
    Свойства: 'Свойства',
    Атрибуты: 'Атрибуты',
    'Исполнилось лет': 'Исполнилось лет',
    Сегмент: 'Сегмент',
    'Количество бонусов': 'Количество бонусов',
    'Драйверы покупки': 'Драйверы покупки',
    Рассылки: 'Рассылки',
    Автоматические: 'Автоматические',
    'Где продано': 'Где продано',
    'Место продажи': 'Место продажи',
    'Что продано': 'Что продано',
    'Где возвращено': 'Где возвращено',
    'Что возвращено': 'Что возвращено',
    'Участник программы': 'Участник программы',
    'Не задан': 'Не задан',
    Мужской: 'Мужской',
    Женский: 'Женский',
    'SMS и Viber': 'SMS, Push и Viber',
    Указан: 'Указан',
    'Не указан': 'Не указан',
    Подтвердил: 'Подтвердил',
    'Не подтвердил': 'Не подтвердил',
    'Ошибка в телефоне': 'Ошибка в телефоне',
    'Ошибка в email': 'Ошибка в email',
    Заблокирован: 'Заблокирован',
    Подписчик: 'Подписчик',
    'Apple Wallet': 'Apple Wallet',
    'Google Pay': 'Google Pay',
    'Да, участник': 'Да, участник',
    Клиент: 'Клиент',
    ИмяКлиента: 'ИмяКлиента',
    ИмяОтчествоКлиента: 'ИмяОтчествоКлиента',
    ФамилияКлиента: 'ФамилияКлиента',
    ОтчествоКлиента: 'ОтчествоКлиента',
    СгораетБонусов: 'СгораетБонусов',
    ВсегоБонусов: 'ВсегоБонусов',
    БлижайшаяДатаСгоранияБонусов: 'БлижайшаяДатаСгоранияБонусов',
    НачисленоБонусовЗаПокупку: 'НачисленоБонусовЗаПокупку',
    ДатаСгоранияБонусовЗаПокупку: 'ДатаСгоранияБонусовЗаПокупку',
    СуммаДоУровня: 'СуммаДоУровня',
    ОтмененоБонусовЗаВозврат: 'ОтмененоБонусовЗаВозврат',
    НачисленоБонусовПоощрения: 'НачисленоБонусовПоощрения',
    ДатаСгоранияБонусовПоощрения: 'ДатаСгоранияБонусовПоощрения',
    'PH.PROMOCODE': 'Промокод',
    КодПодарочнойКарты: 'КодПодарочнойКарты',
    СсылкаЭлКошелька: 'СсылкаЭлКошелька',
    'Товары по триггеру': 'Товары по триггеру',
    НазваниеТовара: 'НазваниеТовара',
    КартинкаТовара: 'КартинкаТовара',
    СтараяЦенаТовара: 'СтараяЦенаТовара',
    ЦенаТовара: 'ЦенаТовара',
    СсылкаНаТовар: 'СсылкаНаТовар',
    'Повторять для каждого товара': 'Повторять для каждого товара',
    'Рекомендуемые товары': 'Рекомендуемые товары',
    'Рассылка удалена': 'Рассылка удалена',
    'Служебные ссылки': 'Служебные ссылки',
    'MAILING.CREATE.ALPHABET_MIXED': 'В тексте SMS вы применили символы русского и латинского алфавита в одном и том же слове ({{words}}). Сотовые операторы считают такие сообщения спамом и могут не доставить их до получателей.',
    Отписаться: 'Отписаться',
    'MAILING.CREATE.MISSING_UNSUB_LINK': 'Вставьте ссылку «Отписаться», чтобы письмо не отправляли в спам',
    'MAILING.CREATE.ZERO_TOTAL_BONUSES': 'Вы применили параметр «!ВсегоБонусов» в тексте {{channels}}, но не отфильтровали клиентов по признаку «больше 0 бонусов на счету». Возможно, клиентов с нулевым балансом следует исключить из рассылки.',
    'Удаление рассылки «{{mailingName}}»': 'Удаление рассылки «{{mailingName}}»',
    Вернуться: 'Вернуться',
    Продолжить: 'Продолжить',
    'Забыли «{{placeholder}}»?': 'Забыли «{{placeholder}}»?',
    'Вы не добавили параметр «{{placeholder}}» в текст {{channels}}': 'Вы не добавили параметр «{{placeholder}}» в текст {{channels}}',
    'Смешанные символы': 'Смешанные символы',
    'Ссылка «Отписаться»': 'Ссылка «Отписаться»',
    'Напоминаем! У вас 0 бонусов': 'Напоминаем! У вас 0 бонусов',
    Подвал: 'Подвал',
    подвал: 'подвал',
    'Карта Wallet': 'Карта Wallet',
    карта: 'карта',
    телефон: 'телефон',
    'Мобильные приложения': 'Мобильные приложения',
    'Товары по триггеру (брошенная корзина)': 'Товары по триггеру (брошенная корзина)',
    товары: 'товары',
    'Товары по триггеру (сниженная цена)': 'Товары по триггеру (сниженная цена)',
    новинки: 'новинки',
    'последние просмотренные': 'последние просмотренные',
    бестселлеры: 'бестселлеры',
    'Все клиенты': 'Все клиенты',
    'Клиенты без карт': 'Клиенты без карт',
    'Все держатели карт': 'Все держатели карт',
    Сегодня: 'Сегодня',
    Вчера: 'Вчера',
    Неделя: 'Неделя',
    Месяц: 'Месяц',
    Квартал: 'Квартал',
    Год: 'Год',
    'Всё время': 'Всё время',
    TOOLTIP_SEGMENTS: 'Группы клиентов, собранные по критериям суммы, частоте покупок, дате последней покупки и дате вступления в клуб. Цель сегментации — удержание клиентов за счет персонализации рассылок',
    TOOLTIP_MAILING_CR: 'Отношение количества клиентов, совершивших целевое действие, к количеству получивших рассылку. Указывается среднее значение по всем каналам, недошедшие сообщения не учитываются. Цель рассылки указывается справа от ее названия',
    'Сумма затрат на программу лояльности для сегмента за выбранный период. Учитываются затраты на платные рассылки, скидки и списание бонусов клиентами': 'Сумма затрат на программу лояльности для сегмента за выбранный период. Учитываются затраты на платные рассылки, скидки и списание бонусов клиентами',
    'Средняя сумма затрат на получение одной покупки с применением программы лояльности для данного сегмента. Учитываются затраты на рассылки, скидки и списание бонусов': 'Средняя сумма затрат на получение одной покупки с применением программы лояльности для данного сегмента. Учитываются затраты на рассылки, скидки и списание бонусов',
    Прибыль: 'Прибыль',
    Выручка: 'Выручка',
    'Клиентов в сегменте': 'Клиентов в сегменте',
    CR: 'CR',
    'Ст-ть покупателя': 'Ст-ть покупателя',
    'Изменить фильтры': 'Изменить фильтры',
    'Удалить сегмент': 'Удалить сегмент',
    'Удалить клиентов': 'Удалить клиентов',
    'Номер телефона, ФИО, email или отсканируйте карту': 'Номер телефона, ФИО, email или отсканируйте карту',
    'Отменить редактирование': 'Отменить редактирование',
    'Сбросить фильтр': 'Сбросить фильтр',
    'Дата вступления клиента в клуб': 'Дата вступления клиента в клуб',
    'Указывается доступная сумма бонусов на счету клиента, а также сумма еще не подтвержденных бонусов (значение в скобках)': 'Указывается доступная сумма бонусов на счету клиента, а также сумма еще не подтвержденных бонусов (значение в скобках)',
    'Статус согласия на получение SMS и/или Email рассылок': 'Статус согласия на получение SMS и/или Email рассылок',
    'Обозначение принадлежности клиента к сегменту; сегментация нужна для персонализации рассылок, а также построения аналитики': 'Обозначение принадлежности клиента к сегменту; сегментация нужна для персонализации рассылок, а также построения аналитики',
    'Новый сегмент': 'Новый сегмент',
    Найти: 'Найти',
    'Редактирование сегмента:': 'Редактирование сегмента:',
    'Сегмент:': 'Сегмент:',
    'Клиентов:': 'Клиентов:',
    'Добавить клиента': 'Добавить клиента',
    'Выгрузить в Excel': 'Выгрузить в Excel',
    'Имя клиента': 'Имя клиента',
    'Сумма покупок с применением программы лояльности за выбранный период. Учитываются чеки с начислением и списанием бонусов. Покупки по которым совершен возврат не учитываются': 'Сумма покупок с применением программы лояльности за выбранный период. Учитываются чеки с начислением и списанием бонусов. Покупки по которым совершен возврат не учитываются',
    'Валовая прибыль покупок с применением программы лояльности за выбранный период. Учитываются чеки с начислением и списанием бонусов. Покупки по которым совершен возврат не учитываются': 'Валовая прибыль покупок с применением программы лояльности за выбранный период. Учитываются чеки с начислением и списанием бонусов. Покупки по которым совершен возврат не учитываются',
    'Сумма затрат на программу лояльности за выбранный период. Учитываются списания бонусов, скидки, затраты на рассылки и абонентская плата': 'Сумма затрат на программу лояльности за выбранный период. Учитываются списания бонусов, скидки, затраты на рассылки и абонентская плата',
    'Количество клиентов, которое вступило в программу лояльности за выбранный период': 'Количество клиентов, которые вступили в программу лояльности за выбранный период',
    'Доля покупок с использованием программы лояльности за выбранный период. Учитываются чеки как с начислением, так и со списанием бонусов': 'Доля покупок с использованием программы лояльности за выбранный период. Учитываются чеки как с начислением, так и со списанием бонусов',
    'Средняя сумма затрат на получение одной покупки с применением программы лояльности. Учитываются затраты на рассылки, скидки и списание баллов': 'Средняя сумма затрат на получение одной покупки с применением программы лояльности. Учитываются затраты на рассылки, скидки и списание баллов',
    'Количество бонусов, которое было начислено в заданный период и остается на бонусных счетах клиентов': 'Количество бонусов, которое было начислено в заданный период и остается на бонусных счетах клиентов',
    'Количество бонусов из числа начисленных за заданный период, которое было использовано клиентами': 'Количество бонусов из числа начисленных за заданный период, которое было использовано клиентами',
    'Количество бонусов из числа начисленных за заданный период, которое сгорело (не были использованы) или было отменено (например, в связи с возвратами)': 'Количество бонусов из числа начисленных за заданный период, которое сгорело (не были использованы) или было отменено (например, в связи с возвратами)',
    'Показатель процента заполнения полей, важных для коммуникации с клиентами': 'Показатель процента заполнения полей, важных для коммуникации с клиентами',
    'Доля от общей прибыли': 'Доля от общей прибыли',
    'Доля от общей выручки': 'Доля от общей выручки',
    'Доля расходов к прибыли': 'Доля расходов к прибыли',
    'Доля расходов к выручке': 'Доля расходов к выручке',
    'Согласны на рассылку SMS': 'Согласны на рассылку SMS',
    'Согласны на рассылку Email': 'Согласны на рассылку Email',
    'Заполнено имя': 'Заполнено имя',
    'Заполнен Email': 'Заполнен Email',
    'Заполнен день рождения': 'Заполнен день рождения',
    'Заполнен пол': 'Заполнен пол',
    Демография: 'Демография',
    День: 'День',
    Группировка: 'Группировка',
    'Сумма затрат на программу лояльности за выбранный период. Учитываются списания бонусов, затраты на рассылки и абонентская плата': 'Сумма затрат на программу лояльности за выбранный период. Учитываются списания бонусов, затраты на рассылки и абонентская плата',
    'Средняя сумма затрат на получение одной покупки с применением программы лояльности. Учитываются затраты на рассылки и списание баллов': 'Средняя сумма затрат на получение одной покупки с применением программы лояльности. Учитываются затраты на рассылки и списание баллов',
    'Количество бонусов, которое было начислено в заданный период и может быть использовано сегодня или позднее': 'Количество бонусов, которое было начислено в заданный период и может быть использовано сегодня или позднее',
    'Количество бонусов, которое было использовано клиентами за выбранный период': 'Количество бонусов, которое было использовано клиентами за выбранный период',
    'Количество бонусов, которое сгорело за выбранный период (не были использованы) или было отменено (например, в связи с возвратами)': 'Количество бонусов, которое сгорело за выбранный период (не были использованы) или было отменено (например, в связи с возвратами)',
    'Новая выгрузка': 'Новая выгрузка',
    Выгрузка: 'Выгрузка',
    'Название роли': 'Название роли',
    'Персон. данные': 'Персон. данные',
    'Начисление бонусов': 'Начисление бонусов',
    'Ручн. рассылки': 'Ручн. рассылки',
    'Авт. рассылки': 'Авт. рассылки',
    'Точки продажи': 'Точки продажи',
    'Промокоды и приведи друга': 'Промокоды\nи приведи друга',
    Маскировать: 'Маскировать',
    'Активация и блокировка': 'Активация и блокировка',
    Название: 'Название',
    Пользователей: 'Пользователей',
    'Установлена Wallet-карта': 'Установлена Wallet-карта',
    'Доступен Telegram': 'Доступен Telegram',
    'Подтвердили через Double Opt-In': 'Подтвердили через Double Opt-In',
    'Всех клиентов': 'Всех клиентов',
    'Только своих': 'Только своих',
    'Всех пользователей': 'Всех пользователей',
    'Всех, кроме админов': 'Всех, кроме админов',
    'Детальная информация о клиентах за выбранный период': 'Детальная информация о клиентах за выбранный период',
    'Применить фильтры': 'Применить фильтры',
    'Список клиентов и их детей': 'Список клиентов и их детей',
    'Базовая информация о клиенте, совмещенная с именем и датой рождения его детей': 'Базовая информация о клиенте, совмещенная с именем и датой рождения его детей',
    'Список покупок': 'Список покупок',
    'Детальная информация о продажах за выбранный период': 'Детальная информация о продажах за выбранный период',
    'Загрузка клиентов по операторам': 'Загрузка клиентов по операторам',
    'Информация о количестве и качестве загруженных операторами анкет': 'Информация о количестве и качестве загруженных операторами анкет',
    'Движение бонусов': 'Движение бонусов',
    'Детальная информация об изменениях в бонусах за выбранный период': 'Детальная информация об изменениях в бонусах за выбранный период',
    Взаиморасчеты: 'Взаиморасчеты',
    'Отчет, позволяющий произвести взаиморасчеты между торговыми точками\n                            по начисленным и списанным бонусам, а также погашенным подарочным картам': 'Отчет, позволяющий произвести взаиморасчеты между торговыми точками\n                            по начисленным и списанным бонусам, а также погашенным подарочным картам',
    'Выгрузки:': 'Выгрузки:',
    'Пока нет выгрузок': 'Пока нет выгрузок',
    'Дата создания': 'Дата создания',
    Статус: 'Статус',
    Состав: 'Состав',
    'Списание с карты бонусов, начисленных при покупке': 'Списание с карты бонусов, начисленных при покупке',
    'Возврат на карту бонусов, списанных при покупке': 'Возврат на карту бонусов, списанных при покупке',
    'Уникальный идентификатор подарочной карты для интеграции с программой лояльности': 'Уникальный идентификатор подарочной карты для интеграции с программой лояльности',
    'Неиспользованный остаток сгорает после первой совершённой покупки': 'Неиспользованный остаток сгорает после первой совершённой покупки',
    'не ограничен (от)': 'не ограничен (от)',
    'не ограничен (до)': 'не ограничен (до)',
    'Карта активируется при передаче клиенту и только после этого становится доступной для использования. Активация должна поддерживаться вашим кассовым ПО, уточните у менеджера, доступна ли эта опция для вас.': 'Карта активируется при передаче клиенту и только после этого становится доступной для использования. Активация должна поддерживаться вашим кассовым ПО, уточните у менеджера, доступна ли эта опция для вас.',
    'Код в формате EAN-13, идентифицирующий карты данного номинала': 'Код в формате EAN-13, идентифицирующий карты данного номинала',
    '2-10 цифр': '2-10 цифр',
    'Редактирование подарочной карты': 'Редактирование подарочной карты',
    'Создание подарочной карты': 'Создание подарочной карты',
    Основное: 'Основное',
    'Тип карты': 'Тип карты',
    Печатная: 'Печатная',
    Электронная: 'Электронная',
    Идентификатор: 'Идентификатор',
    'Сумма номинала': 'Сумма номинала',
    'сгораемый остаток': 'сгораемый остаток',
    'Период действия карты': 'Период действия карты',
    'требуется активация карты': 'требуется активация карты',
    'Срок действия с момента активации': 'Срок действия с момента активации',
    дней: 'дней',
    'Печатная карта': 'Печатная карта',
    'Товарный код': 'Товарный код',
    'Артикул карты': 'Артикул карты',
    'Тип штрих-кода': 'Тип уникального кода',
    'Префикс штрих-кода': 'Префикс уникального кода',
    'Создать карту': 'Создать карту',
    'Штрих-код': 'Штрих-код',
    'Выпущено карт:': 'Выпущено карт:',
    'Выгрузить коды в файл': 'Выгрузить коды в файл',
    'Номер карты': 'Номер карты',
    'Дата активации': 'Дата активации',
    'Дата истечения': 'Дата истечения',
    Остаток: 'Остаток',
    gift_status_not_activated: 'Не активирована',
    gift_status_active: 'Активирована',
    gift_status_blocked: 'Заблокирована',
    gift_status_expired: 'Истекла',
    gift_status_burned: 'Погашена',
    Активировать: 'Активировать',
    'Сохранить черновик': 'Сохранить черновик',
    'Заменять существующие данные': 'Заменять существующие данные',
    Лист: 'Лист',
    'Пропустить заголовок': 'Пропустить заголовок',
    строк: 'строк',
    'Не загружать': 'Не загружать',
    'ФИО одним полем': 'ФИО одним полем',
    'Дата рождения': 'Дата рождения',
    'Внешний идентификатор': 'Внешний идентификатор',
    'Дата регистрации': 'Дата регистрации',
    'Дата последней покупки': 'Дата последней покупки',
    'Кол-во покупок': 'Кол-во покупок',
    'Кол-во': 'Кол-во',
    'Кол-во бонусов': 'Кол-во бонусов',
    'Согласие на любые рассылки': 'Согласие на любые рассылки',
    'Согласие на SMS': 'Согласие на SMS',
    'Согласие на Email': 'Согласие на Email',
    'Отображено строк:': 'Отображено строк:',
    из: 'из',
    Загрузить: 'Загрузить',
    Загружается: 'Загружается',
    Выполнена: 'Выполнена',
    Ошибка: 'Ошибка',
    Черновик: 'Черновик',
    'Скачать файл с результатом': 'Скачать файл с результатом',
    'Загрузить данные': 'Загрузить данные',
    'Загрузить клиентов': 'Загрузить клиентов',
    'Дата загрузки': 'Дата загрузки',
    Тип: 'Тип',
    'Всего записей': 'Всего записей',
    Загружено: 'Загружено',
    'С ошибкой': 'С ошибкой',
    Редактировать: 'Редактировать',
    'Перезагрузить результат': 'Перезагрузить результат',
    'Актуальные счета': 'Актуальные счета',
    'Дата выставления': 'Дата выставления',
    Номер: 'Номер',
    'Дата оплаты': 'Дата оплаты',
    'Не оплачен': 'Не оплачен',
    Оплачен: 'Оплачен',
    'Частично оплачен': 'Частично оплачен',
    Отменен: 'Отменен',
    'Запросить счет': 'Запросить счет',
    'Имя пользователя': 'Имя пользователя',
    Пароль: 'Пароль',
    Войти: 'Войти',
    Роли: 'Роли',
    'Создать роль': 'Создать роль',
    'Создание роли': 'Создание роли',
    'Редактирование роли': 'Редактирование роли',
    Настройки: 'Настройки',
    'Рассчитано приблизительно на основе средней стоимости отправки, количества получателей и длины сообщения. Фактические затраты могут отличаться.': 'Рассчитано приблизительно на основе средней стоимости отправки, количества получателей и длины сообщения. Фактические затраты могут отличаться.',
    Общее: 'Общее',
    Push: 'Push',
    Viber: 'Viber',
    SMS: 'SMS',
    Завершение: 'Завершение',
    'Прогнозируемые затраты до': 'Прогнозируемые затраты до',
    'в неделю.': 'в неделю.',
    'Тестовый Viber': 'Тестовый Viber',
    'Тестовый Push': 'Тестовый Push',
    'Тестовое SMS': 'Тестовое SMS',
    'Тестовое письмо': 'Тестовое письмо',
    Назад: 'Назад',
    Далее: 'Далее',
    Отправлять: 'Отправлять',
    Запланировать: 'Запланировать',
    'MAILING.MESSAGE_WILL_BE_RECEIVED': "Сообщение {{ clientsCount | plural:'получит':'получат':'получат' }} <b>{{ clientsCount | numberRound }}</b> {{ clientsCount | plural:'клиент':'клиента':'клиентов' }}.",
    EMAIL_INCLUDE_ABANDONED_CART: 'В редакторе появился блок «Товары по триггеру». Вставьте его в тело письма, чтобы вывести получателю список товаров из корзины (отобразится не более 10 товаров)',
    EMAIL_INCLUDE_DROPPED_PRICE_ITEMS: 'В редакторе появился блок «Товары по триггеру». Вставьте его в тело письма, чтобы вывести получателю список товаров со сниженной ценой (отобразится не более 10 товаров)',
    EMAIL_INCLUDE_ITEMS_LIST: 'Отметьте, чтобы в редакторе появился блок «Рекомендуемые товары». Вставьте его в тело письма для вывода получателю списка товарных рекомендаций (отобразится заданное вами количество товаров)',
    Отправитель: 'Отправитель',
    'Тема письма': 'Тема письма',
    Шаблон: 'Шаблон',
    Содержимое: 'Содержимое',
    Требуется: 'Требуется',
    'Выбрать дизайн': 'Выбрать дизайн',
    'или создать новый': 'или создать новый',
    Новый: 'Новый',
    'Очистить дизайн': 'Очистить дизайн',
    'Вложить в письмо брошенные товары': 'Вложить в письмо брошенные товары',
    'Вложить в письмо товары со сниженной ценой': 'Вложить в письмо товары со сниженной ценой',
    'Вложить в письмо товарные рекомендации': 'Вложить в письмо товарные рекомендации',
    Подборка: 'Подборка',
    PRODUCTS_IN_EMAIL: "{{ count | plural:'товар':'товара':'товаров' }} в письме",
    'Используется основной период действия акции или указанное значение': 'Используется основной период действия акции или указанное значение',
    'Используется только в интерфейсе MAXMA': 'Используется только в интерфейсе MAXMA',
    'Для показа конверсии (CR) достижения цели рассылки': 'Для показа конверсии (CR) достижения цели рассылки',
    'Событие (триггер), при наступлении которого произойдет отправка': 'Событие (триггер), при наступлении которого произойдет отправка',
    'События сайта': 'События сайта',
    Событие: 'Событие',
    Прочее: 'Прочее',
    TOOLTIP_MAILING_REPEAT_SENDING: 'Да – отправка будет проходить каждый раз при срабатывании триггера отправки (например, ежегодно на день рождения или при повторном попадании в сегмент); Нет – клиент, получивший рассылку, больше никогда ее не получит',
    OFFSET_SIGN_BEFORE: 'до',
    OFFSET_SIGN_AFTER: 'после',
    REWARD_PROMOCODE: 'Промокод «{{ promocodeCode }}»',
    REWARD_GIFT_CARD: 'Подарочная карта «{{ name }}» ({{ amount }})',
    Начислить: 'Начислить',
    'От 02.01.1900': 'От 02.01.1900',
    'До 31.12.2099': 'До 31.12.2099',
    ', сгорают через': ', сгорают через',
    'сгорают через': 'сгорают через',
    сгорают: 'сгорают',
    'после начисления': 'после начисления',
    REWARD_OFFER: 'Акция «{{ title }}», действует',
    REWARD_OFFER_SINCE_MAILING: 'с момента рассылки',
    Каналы: 'Каналы',
    Получатели: 'Получатели',
    'Задайте получателей рассылки.': 'Задайте получателей рассылки.',
    'Считать CR': 'Считать CR',
    'подтверждение подписки (Email)': 'подтверждение подписки (Email)',
    'заполнение анкеты': 'заполнение анкеты',
    'доставки рассылки': 'доставки рассылки',
    'прохождения опроса': 'прохождения опроса',
    'установку карты Wallet': 'установку карты Wallet',
    'переход по ссылке (Email)': 'переход по ссылке (Email)',
    нет: 'нет',
    'не считать': 'не считать',
    'Дата и время': 'Дата и время',
    Сейчас: 'Сейчас',
    'Отложить до': 'Отложить до',
    'Ваш часовой пояс:': 'Ваш часовой пояс:',
    'MAILING.SEND_ON': 'Отправить',
    сразу: 'сразу',
    через: 'через',
    за: 'за',
    'попадания в сегмент': 'попадания в сегмент',
    'покидания сегмента': 'покидания сегмента',
    'активации бонусов': 'активации бонусов',
    возврата: 'возврата',
    'ухода с сайта (брошенная корзина)': 'ухода с сайта (брошенная корзина)',
    'ухода с сайта (брошенный просмотр товара или категории)': 'ухода с сайта (брошенный просмотр товара или категории)',
    'снижения цены на товар в корзине': 'снижения цены на товар в корзине',
    'снижения цены на товар в избранном': 'снижения цены на товар в избранном',
    'дня рождения': 'дня рождения',
    'дня рождения ребенка': 'дня рождения ребенка',
    'сгорания бонусов': 'сгорания бонусов',
    'Задайте необходимые критерии.': 'Задайте необходимые критерии.',
    'Повторять отправку': 'Повторять отправку',
    Поощрения: 'Поощрения',
    Добавить: 'Добавить',
    TOOLTIP_MAILING_PUSH_TEXT: 'В тексте допускаются любые символы юникода, в т.ч. русские буквы. Рекомендуемая длина — не более 80 символов',
    'Push-нотификации': 'Push-нотификации',
    'Текст сообщения': 'Текст сообщения',
    'Символов:': 'Символов:',
    'Динамические параметры:': 'Динамические параметры:',
    'Показывать баннер': 'Показывать баннер',
    'Картинка загружена': 'Картинка загружена',
    'Поместите картинку': 'Поместите картинку',
    '(png, 1125x433, до 10 Мб)': '(png, 1125x433, до 10 Мб)',
    TOOLTIP_MAILING_SMS_TEXT: 'В тексте SMS допускаются любые символы юникода, в т.ч. русские буквы. Рекомендуемая длина SMS — не более 6 частей',
    SMS_TEXT_LENGTH: "Текст содержит {{ smsMessagesCount }} SMS ({{ smsTextLength }} {{ smsTextLength | plural:'символ':'символа':'символов' }})",
    TOOLTIP_MAILING_VIBER_TEXT: 'В тексте допускаются любые символы юникода, в т.ч. русские буквы. Рекомендуемая длина — не более 400 символов',
    'Обязательна при использовании кнопки или картинки': 'Обязательна при использовании кнопки или картинки',
    'Текст кнопки': 'Текст кнопки',
    '(квадратная и до 1 Мб)': '(квадратная и до 1 Мб)',
    Ссылка: 'Ссылка',
    'Соединить в цепочку': 'Соединить в цепочку',
    Триггеры: 'Триггеры',
    TOOLTIP_MAILING_CHANNELS: 'Каналы коммуникации, используемые в рассылке. Используется до 3-х каналов: Email, мессенджер Viber и SMS. SMS отправляется, если через Viber сообщение не дошло или канал отключен вовсе',
    TOOLTIP_ROI_PROFIT: 'Возврат вложений. Рассчитывается как (Прибыль − Расходы) ∕ Расходы × 100%',
    TOOLTIP_ROI_REVENUE: 'Возврат вложений. Рассчитывается как (Выручка − Расходы) ∕ Расходы × 100%',
    TOOLTIP_MAILING_PROFIT: 'Валовая прибыль от заказов и розничных продаж, сделанных с применением карт, за две недели после рассылки. Если клиент получал несколько рассылок, то прибыль засчитывается последней из них. Отмененные заказы не учитываются',
    TOOLTIP_MAILING_REVENUE: 'Сумма заказов и розничных продаж, сделанных с применением карт, за две недели после рассылки. Если клиент получал несколько рассылок, то выручка засчитывается последней из них. Отмененные заказы не учитываются',
    'Сумма затрат на рассылку за выбранный период. Учитываются списания бонусов, скидки и затраты на рассылки по всем каналам': 'Сумма затрат на рассылку за выбранный период. Учитываются списания бонусов, скидки и затраты на рассылки по всем каналам',
    Запланирована: 'Запланирована',
    Запланирован: 'Запланирован',
    Отправляется: 'Отправляется',
    Дата: 'Дата',
    ROI: 'ROI',
    MAILING_RECIPIENTS_SEGMENTS_COUNT: "{{ segmentsCount }} {{ segmentsCount | plural:'сегмент':'сегмента':'сегментов' }}",
    исключения: 'исключения',
    фильтры: 'фильтры',
    'Сделать копию': 'Сделать копию',
    Посмотреть: 'Посмотреть',
    'Итого за период': 'Итого за период',
    'Добавить рассылку': 'Добавить рассылку',
    Отображать: 'Отображать',
    результатов: 'результатов',
    Применить: 'Применить',
    'Выберите дизайн': 'Выберите дизайн',
    'Начать с чистого листа': 'Начать с чистого листа',
    'На основе предыдущей рассылки': 'На основе предыдущей рассылки',
    'На основе шаблона': 'На основе шаблона',
    Обзор: 'Обзор',
    'Редактировать профиль': 'Редактировать профиль',
    Подтверждается: 'Подтверждается',
    Карта: 'Карта',
    Покупок: 'Покупок',
    Возвратов: 'Возвратов',
    'История клиента': 'История клиента',
    сейчас: 'сейчас',
    'В резерве': 'В резерве',
    'за заказом или покупкой': 'за заказом или покупкой',
    Потрачено: 'Потрачено',
    'на покупку': 'на покупку',
    Накоплено: 'Накоплено',
    'за покупку': 'за покупку',
    Отменено: 'Отменено',
    'за возврат': 'за возврат',
    Начислено: 'Начислено',
    оператором: 'оператором',
    Списано: 'Списано',
    Возвращено: 'Возвращено',
    'за покупку друга': 'за покупку друга',
    'Клиент вступил в Клуб': 'Клиент вступил в Клуб',
    'Начислить, списать бонусы': 'Начислить, списать бонусы',
    'Добавить покупку': 'Добавить покупку',
    HISTORY_BONUSES_AMOUNT: '{{amount}} $t(bonuses, { "count": {{count}} })',
    BONUSES_AMOUNT_MIN_ERROR_MESSAGE: 'минимум {{amount}} $t(bonuses, { "count": {{count}} })',
    BONUSES_AMOUNT_MAX_ERROR_MESSAGE: 'не более {{amount}} $t(bonuses, { "count": {{count}} })',
    BONUSES_AMOUNT: '{{amount}} $t(bonuses, { "count": {{count}} })',
    BONUSES_LABEL: '$t(bonuses, { "count": {{count}} })',
    bonuses_one: 'бонус',
    bonuses_few: 'бонуса',
    bonuses_many: 'бонусов',
    'Номер телефона, ФИО, Email или карта': 'Номер телефона, ФИО, Email или карта',
    'Клиенту будут направлены стандартные сообщения о начислении/списании бонусов': 'Клиенту будут направлены стандартные сообщения о начислении/списании бонусов',
    'Начислить бонусы': 'Начислить бонусы',
    'Списать бонусы': 'Списать бонусы',
    'Для клиента': 'Для клиента',
    'Тип операции': 'Тип операции',
    Списать: 'Списать',
    'Срок действия': 'Срок действия',
    'как у обычных бонусов': 'как у обычных бонусов',
    '1 день': '1 день',
    '3 дня': '3 дня',
    '1 неделя': '1 неделя',
    '2 недели': '2 недели',
    '1 месяц': '1 месяц',
    '3 месяца': '3 месяца',
    '6 месяцев': '6 месяцев',
    '1 год': '1 год',
    Комментарий: 'Комментарий',
    'Уведомить клиента': 'Уведомить клиента',
    'Вставьте данные': 'Вставьте данные',
    'Поместите файл': 'Поместите файл',
    '(XLSX, CSV, до 15 Мб)': '(XLSX, CSV, до 15 Мб)',
    или: 'или',
    'загрузите из буфера обмена': 'загрузите из буфера обмена',
    'Запросить новый счет на пополнение': 'Запросить новый счет на пополнение',
    'На сумму': 'На сумму',
    Выставить: 'Выставить',
    GIFT_CARD_GENERATE_MODAL_TITLE: 'Подарочная карта «{{ giftCard.name }}», {{ giftCard.amount }}',
    PROMOCODE_GENERATE_MODAL_TITLE: 'Промокод "{{ promocode.code }}"',
    'Сгенерировать и выгрузить дополнительные карты': 'Сгенерировать и выгрузить дополнительные карты',
    GIFT_CARD_GENERATE_MODAL_OPTION_GENERATE: 'Выгрузить существующие карты ({{ amount }} шт)',
    GIFT_CARD_GENERATE_HELP: 'от {{ from }} до {{ to }} карт',
    PROMOCODE_GENERATE_HELP: 'от {{ from }} до {{ to }} кодов',
    PROMOCODE_GENERATE_REPORT_NOTICE: 'Файл с кодами будет доступен в разделе Выгрузки в течение 14 дней.',
    Выгрузить: 'Выгрузить',
    'Сгенерировать коды': 'Сгенерировать коды',
    Сгенерировать: 'Сгенерировать',
    'Создать акцию': 'Создать акцию',
    'Создать промокод': 'Создать промокод',
    Статистика: 'Статистика',
    'Количество сообщений, в которых получатель нажал «отписаться» или «спам», а также доля таких сообщений от количества доставленных.': 'Количество сообщений, в которых получатель нажал «отписаться» или «спам», а также доля таких сообщений от количества доставленных.',
    TOOLTIP_MAILING_POPUP_CR: 'Отношение количества клиентов, совершивших целевое действие ({{ goalDescr }}), к количеству получивших рассылку. Указывается среднее значение по всем каналам, недошедшие сообщения не учитываются',
    'Сумма затрат по всем каналам рассылки': 'Сумма затрат по всем каналам рассылки',
    TOOLTIP_FAILED_COUNT: '{{amount}} $t(errors, { "count": {{count}} })',
    errors_one: 'ошибка',
    errors_few: 'ошибки',
    errors_many: 'ошибок',
    'Триггер:': 'Триггер:',
    'Цель:': 'Цель:',
    Вкл: 'Вкл',
    'Вкл/Выкл': 'Вкл\n/Выкл',
    Отправлено: 'Отправлено',
    Ожидают: 'Ожидают',
    'Сообщения могут ожидать отправки, если они:': 'Сообщения могут ожидать отправки, если они:',
    'находятся в очереди и будут обработаны в ближайшее время;': 'находятся в очереди и будут обработаны в ближайшее время;',
    'отложены на утро с учетом часового пояса получателя.': 'отложены на утро с учетом часового пояса получателя.',
    'Open Rate — количество просмотренных сообщений, а также доля просмотренных от числа доставленных.': 'Open Rate — количество просмотренных сообщений, а также доля просмотренных от числа доставленных.',
    'Click-to-Open Rate — количество сообщений, в которых получатель перешел по ссылке, а также доля таких сообщений от числа просмотренных.': 'Click-to-Open Rate — количество сообщений, в которых получатель перешел по ссылке, а также доля таких сообщений от числа просмотренных.',
    Доставлено: 'Доставлено',
    Прочитано: 'Прочитано',
    Кликов: 'Кликов',
    Отписок: 'Отписок',
    'Доступ открыт до': 'Доступ открыт до',
    Итого: 'Итого',
    'ДД.ММ.ГГГГ': 'ДД.ММ.ГГГГ',
    'Чтобы привязать к клиенту карту, поместите курсор в данное поле и отсканируйте карту, номер карты отобразится в поле': 'Чтобы привязать к клиенту карту, поместите курсор в данное поле и отсканируйте карту, номер карты отобразится в поле',
    Отсканируйте: 'Отсканируйте',
    'Удалить ребенка': 'Удалить ребенка',
    'Добавить ребенка': 'Добавить ребенка',
    'Нераспознанное имя:': 'Нераспознанное имя:',
    'Имя и отчество': 'Имя и отчество',
    'Мобильный телефон': 'Мобильный телефон',
    'Некорректный телефон': 'Некорректный телефон',
    'Некорректный Email': 'Некорректный Email',
    'Место выдачи карты': 'Место выдачи карты',
    'Уровень клиента': 'Уровень клиента',
    Ребенок: 'Ребенок',
    'Согласие на рассылку': 'Согласие на рассылку',
    'Сохранить изменения': 'Сохранить изменения',
    'Удалить клиента': 'Удалить клиента',
    'Наименование или артикул': 'Наименование или артикул',
    'Выберите клиента': 'Выберите клиента',
    'Указать состав покупки': 'Указать состав покупки',
    PURCHASE_TO_REDEEM_AMOUNT: '$t(bonuses, { "count": {{count}} })',
    PURCHASE_FROM_AVAILABLE: 'из {{amount}} $t(available, { "count": {{count}} })',
    available_one: 'доступного',
    available_few: 'доступных',
    available_many: 'доступных',
    Скидка: 'Скидка',
    'К оплате': 'К оплате',
    'Будет начислено': 'Будет начислено',
    PURCHASE_TO_EARN_AMOUNT: '{{amount}} $t(bonuses, { "count": {{count}} })',
    '0 бонусов': '0 бонусов',
    'Сумма со скидкой': 'Сумма со скидкой',
    'Бонусов списано': 'Бонусов списано',
    'Бонусов начислено': 'Бонусов начислено',
    Оплачено: 'Оплачено',
    'Удалить покупку': 'Удалить покупку',
    Регион: 'Регион',
    'Каталог товаров': 'Каталог товаров',
    Создать: 'Создать',
    'Изменить покупку': 'Изменить покупку',
    'Редактирование сегмента': 'Редактирование сегмента',
    Цвет: 'Цвет',
    'Сохранить сегмент': 'Сохранить сегмент',
    'Создать сегмент': 'Создать сегмент',
    'Новая точка продаж': 'Новая точка продаж',
    'Редактирование точки продаж': 'Редактирование точки продаж',
    Поищи: 'Поищи',
    'Можете указать несколько адресов email, по одному на каждую строку': 'Можете указать несколько адресов email, по одному на каждую строку',
    'Где покупал хотя бы раз': 'Где покупал хотя бы раз',
    'Последнее место покупки': 'Последнее место покупки',
    'Самое частое место покупки': 'Самое частое место покупки',
    'Можете указать несколько номеров в любом формате, по одному на каждую строку': 'Можете указать несколько номеров в любом формате, по одному на каждую строку',
    'Тестовое сообщение': 'Тестовое сообщение',
    'Номера телефонов': 'Номера телефонов',
    'Действия пользователей платформы': 'Действия пользователей платформы',
    'Действия пользователей в платформе, такие как создание, изменение или удаление клиентов, акций, рассылок и так далее': 'Действия пользователей в платформе, такие как создание, изменение или удаление клиентов, акций, рассылок и так далее',
    Пользователь: 'Пользователь',
    'Сгенерировать пароль': 'Сгенерировать пароль',
    Логин: 'Логин',
    'Все события': 'Все события',
    'Только латиница': 'Только латиница',
    'Придумайте пароль': 'Придумайте пароль',
    'Роль (уровень доступа)': 'Роль (уровень доступа)',
    'Отправить данные для входа на почту': 'Отправить данные для входа на почту',
    'Видят только администраторы': 'Видят только администраторы',
    'Создать пользователя': 'Создать пользователя',
    'или укажите сегмент, телефон': 'или укажите сегмент, телефон',
    'Редактирование акции': 'Редактирование акции',
    'Создание акции': 'Создание акции',
    Включена: 'Включена',
    Остановлена: 'Остановлена',
    Включен: 'Включен',
    Остановлен: 'Остановлен',
    Участники: 'Участники',
    'Некоторые из телефонов содержат ошибки.': 'Некоторые из телефонов содержат ошибки.',
    'Убрать некорректные': 'Убрать некорректные',
    'Выберите хотя бы одного участника': 'Выберите хотя бы одного участника',
    MAILING_RECIPIENTS: 'Получатели рассылки <2>«{{ name }}»</2>',
    'Период действия акции': 'Период действия акции',
    'Кол-во участий': 'Кол-во участий',
    'не ограничено': 'не ограничено',
    'не более': 'не более',
    неделю: 'неделю',
    акцию: 'акцию',
    'Эта акция': 'Эта акция',
    'единственная в чеке': 'единственная в чеке',
    'вытесняет другие': 'вытесняет другие',
    'уступает другим': 'уступает другим',
    'срабатывает, если выгоднее': 'срабатывает, если выгоднее',
    'суммируется с другими': 'суммируется с другими',
    'не выбрано': 'не выбрано',
    'Условия срабатывания': 'Условия срабатывания',
    'При покупке': 'При покупке',
    'В чеке есть': 'В чеке есть',
    'Время покупки': 'Время покупки',
    Пн: 'Пн',
    Вт: 'Вт',
    Ср: 'Ср',
    Чт: 'Чт',
    Пт: 'Пт',
    Сб: 'Сб',
    Вс: 'Вс',
    Вознаграждения: 'Вознаграждения',
    Вознаграждение: 'Вознаграждение',
    скидка: 'скидка',
    'растущая скидка': 'растущая скидка',
    бонусы: 'бонусы',
    'фиксированная цена': 'фиксированная цена',
    прибавляются: 'прибавляются',
    'начисляются вместо': 'начисляются вместо',
    'к бонусам по ПЛ': 'к бонусам по ПЛ',
    'бонусов ПЛ': 'бонусов ПЛ',
    'в дату': 'в дату',
    никогда: 'никогда',
    активируются: 'активируются',
    'после активации': 'после активации',
    'На товар по счету': 'На товар по счету',
    'на чек': 'на чек',
    'на любой товар': 'на любой товар',
    'на указанный товар': 'на указанный товар',
    'на комплект (комбо)': 'на комплект (комбо)',
    'Выберите товары': 'Выберите товары',
    'Выберите хотя бы один критерий применения': 'Выберите хотя бы один критерий применения',
    'на каждый': 'на каждый',
    '-й товар в чеке': '-й товар в чеке',
    OFFER_NOT_MORE_N_ITEMS: '$t(offer_not_more_items, { "count": {{count}} }) за покупку',
    offer_not_more_items_one: 'товара',
    offer_not_more_items_few: 'товаров',
    offer_not_more_items_many: 'товаров',
    'выбирать самый дешевый товар': 'выбирать самый дешевый товар',
    'разделить скидку между всеми товарами в чеке': 'разделить скидку между всеми товарами в чеке',
    'Сервис на этапе интеграции. Абонентская плата не списывается, рассылки не срабатывают.': 'Сервис на этапе интеграции. Абонентская плата не списывается, рассылки не срабатывают.',
    'Работа сервиса может быть приостановлена': 'Работа сервиса может быть приостановлена',
    'Пополните баланс': 'Пополните баланс',
    'Пополните баланс до {{date}}': 'Пополните баланс до {{date}}',
    BALANCE_FORECAST_ALERT: 'Текущего баланса {{currentBalance}} хватит на $t(days, { "count": {{days}} })',
    days_one: '~{{count}} день',
    days_few: '~{{count}} дня',
    days_many: '~{{count}} дней',
    MIN_DAYS_ERROR_MESSAGE: 'не менее {{amount}} $t(min_max_days, { "count": {{days}} })',
    MAX_DAYS_ERROR_MESSAGE: 'не более {{amount}} $t(min_max_days, { "count": {{days}} })',
    min_max_days_one: 'дня',
    min_max_days_few: 'дней',
    min_max_days_many: 'дней',
    'Работа сервиса приостановлена. Абонентская плата не списывается, рассылки не срабатывают.': 'Работа сервиса приостановлена. Абонентская плата не списывается, рассылки не срабатывают.',
    LOGOUT: 'Выйти',
    BALANCE_FORECAST_TOOLTIP: 'Хватит на $t(days, { "count": {{days}} })',
    Клиента: 'Клиента',
    Покупку: 'Покупку',
    'Общий – может быть применен разными клиентами. Персональный – может быть применен только тем клиентом, кому был направлен в рассылке.': 'Общий – может быть применен разными клиентами. Персональный – может быть применен только тем клиентом, кому был направлен в рассылке.',
    'не ограничен': 'не ограничен',
    'Минимальная сумма чека для применения промокода': 'Минимальная сумма чека для применения промокода',
    'Сумма бонусов, которую получит рекомендатель сразу после покупки друга': 'Сумма бонусов, которую получит рекомендатель сразу после покупки друга',
    'Важно проинформировать рекомендателя о вознаграждении, чтобы он продолжал рассказывать о вашем бренде': 'Важно проинформировать рекомендателя о вознаграждении, чтобы он продолжал рассказывать о вашем бренде',
    'Редактирование промокода': 'Редактирование промокода',
    'Создание промокода': 'Создание промокода',
    'Редактирование кода': 'Редактирование кода',
    'Создание кода': 'Создание кода',
    'Написание кода': 'Написание кода',
    'Префикс кода': 'Префикс кода',
    'Создать код': 'Создать код',
    'Тип кода': 'Тип кода',
    'Общий код': 'Общий код',
    'Персональные коды': 'Персональные коды',
    'Период действия': 'Период действия',
    'Сумма покупки от': 'Сумма покупки от',
    'Только на': 'Только на',
    'Любую покупку': 'Любую покупку',
    'Первую покупку': 'Первую покупку',
    'Одну покупку': 'Одну покупку',
    'В торговых точках': 'В торговых точках',
    'Размер скидки': 'Размер скидки',
    'Вознаграждение рекомендателя': 'Вознаграждение рекомендателя',
    '% от покупки друга в бонусах': '% от покупки друга в бонусах',
    'Начислять за': 'Начислять за',
    'Все покупки друга': 'Все покупки друга',
    'Одну покупку друга': 'Одну покупку друга',
    'Уведомить о начислении': 'Уведомить о начислении',
    Действующие: 'Действующие',
    Архивные: 'Архивные',
    'Место, где была совершена покупка': 'Место, где была совершена покупка',
    'Учетная запись, под которой провели покупку': 'Учетная запись, под которой провели покупку',
    'Чеков:': 'Чеков:',
    'Дата операции': 'Дата операции',
    'Точка продажи': 'Точка продажи',
    'Полный доступ': 'Полный доступ',
    Сотрудник: 'Сотрудник',
    EMPLOYEE: '{{amount}} $t(employee, { "count": {{count}} })',
    employee_one: 'сотрудник',
    employee_few: 'сотрудника',
    employee_many: 'сотрудников',
    Бонусов: 'Бонусов',
    списано: 'списано',
    начислено: 'начислено',
    'Для настройки акций обратитесь к менеджеру': 'Для настройки акций обратитесь к менеджеру',
    'Для настройки подарочных карт обратитесь к менеджеру': 'Для настройки подарочных карт обратитесь к менеджеру',
    Отчеты: 'Отчеты',
    Выгрузки: 'Выгрузки',
    Инструменты: 'Инструменты',
    Биллинг: 'Биллинг',
    Поддержка: 'Поддержка',
    Настройка: 'Настройка',
    'Указана окончательная стоимость, вне зависимости от местонахождения клиента. Все сообщения будут отправляться от имени Вашего бренда': 'Указана окончательная стоимость, вне зависимости от местонахождения клиента. Все сообщения будут отправляться от имени Вашего бренда',
    'Количество целевых действий канала, за которое списывается плата, за отчетный период. Для Email и SMS — это отправка сообщения, для Viber — факт доставки сообщения': 'Количество целевых действий канала, за которое списывается плата, за отчетный период. Для Email и SMS — это отправка сообщения, для Viber — факт доставки сообщения',
    'Плата за использование платформы за выбранный период': 'Плата за использование платформы за выбранный период',
    'Количество дней в выбранном расчетном периоде, за которое будет начислена плата, согласно тарифу': 'Количество дней в выбранном расчетном периоде, за которое будет начислена плата, согласно тарифу',
    'Детализация расходов за:': 'Детализация расходов за:',
    'Расходы на рассылки': 'Расходы на рассылки',
    'Стоимость сообщения': 'Стоимость сообщения',
    'Количество сообщений': 'Количество сообщений',
    Подытог: 'Подытог',
    'Абонентская плата': 'Абонентская плата',
    'Стоимость дня': 'Стоимость дня',
    'Количество дней': 'Количество дней',
    'Ежедневное списание': 'Ежедневное списание',
    'Итого:': 'Итого:',
    'Сумма покупок за период (исключая возвраты), а также прибыль за счет истекших подарочных карт и сгоревших остатков на подарочных картах.': 'Сумма покупок за период (исключая возвраты), а также прибыль за счет истекших подарочных карт и сгоревших остатков на подарочных картах.',
    'Валовая прибыль с покупок за период (исключая возвраты), а также прибыль за счет истекших подарочных карт и сгоревших остатков на подарочных картах.': 'Валовая прибыль с покупок за период (исключая возвраты), а также прибыль за счет истекших подарочных карт и сгоревших остатков на подарочных картах.',
    'Сумма списанных бонусов и затрат на рассылки, в которых распространялась эта карта': 'Сумма списанных бонусов и затрат на рассылки, в которых распространялась эта карта',
    Выпущено: 'Выпущено',
    шт: 'шт',
    Активировано: 'Активировано',
    Погашено: 'Погашено',
    Истекло: 'Истекло',
    'Сумма применённых скидок и списанных бонусов': 'Сумма применённых скидок и списанных бонусов',
    TOOLTIP_OFFER_PROFIT: 'Валовая прибыль от проданных в розницу и заказанных товаров по акции за выбранный период. Возвращенные товары и отмененные заказы не учитываются',
    TOOLTIP_OFFER_REVENUE: 'Сумма проданных в розницу и заказанных товаров по акции за выбранный период. Возвращенные товары и отмененные заказы не учитываются',
    TOOLTIP_OFFER_COUNT: 'Количество покупок с применением акции, включая выполненные заказы',
    TOOLTIP_OFFER_ORDER_COUNT: 'Количество заказов в работе (не отмененных, не выполненных) с применением акции',
    TOOLTIP_OFFER_INACTIVE: 'Отключена',
    TOOLTIP_PROMOCODE_PROFIT: 'Валовая прибыль с розничных продаж и заказов с применением промокода за выбранный период. Возвращенные товары и отмененные заказы не учитываются',
    TOOLTIP_PROMOCODE_REVENUE: 'Сумма розничных продаж и заказов с применением промокода за выбранный период. Возвращенные товары и отмененные заказы не учитываются',
    TOOLTIP_PROMOCODE_COUNT: 'Количество покупок с применением промокода, включая выполненные заказы',
    TOOLTIP_PROMOCODE_OFFER_COUNT: 'Количество заказов в работе (не отмененных, не выполненных) с применением промокода',
    'Сумма применённых скидок, списанных бонусов и затрат на рассылки, в которых распространялся этот код': 'Сумма применённых скидок, списанных бонусов и затрат на рассылки, в которых распространялся этот код',
    Применений: 'Применений',
    'Могут использовать': 'Могут использовать',
    Товары: 'Товары',
    'Список товаров': 'Список товаров',
    'Список подешевевших товаров': 'Список подешевевших товаров',
    От: 'От',
    с: 'с',
    'Рекомендации товаров': 'Рекомендации товаров',
    'брошенная корзина': 'брошенная корзина',
    'просмотр товара или категории': 'просмотр товара или категории',
    'цена на товар в корзине снижена': 'цена на товар в корзине снижена',
    'цена на товар в избранном снижена': 'цена на товар в избранном снижена',
    'Пользователей:': 'Пользователей:',
    'Добавить пользователя': 'Добавить пользователя',
    'Дата добавления': 'Дата добавления',
    Роль: 'Роль',
    'Последняя авторизация': 'Последняя авторизация',
    'Точек продаж:': 'Точек продаж:',
    'Часовой пояс': 'Часовой пояс',
    'Расчетный период': 'Расчетный период',
    'Социальные сети': 'Социальные сети',
    соцсети: 'соцсети',
    'Создание рассылки': 'Создание рассылки',
    'Источник покупки': 'Источник покупки',
    'Адрес Email': 'Адрес Email',
    Адрес: 'Адрес',
    'Системная роль': 'Системная роль',
    Координаты: 'Координаты',
    'введите координаты': 'введите координаты',
    'введите адрес': 'введите адрес',
    метров: 'метров',
    всегда: 'всегда',
    'заданный период': 'заданный период',
    'Уведомления вблизи магазинов': 'Уведомления вблизи магазинов',
    'Как найти координаты магазина': 'Как найти координаты магазина',
    'на дистанции до': 'на дистанции до',
    MAX_METERS_ERROR: 'не более {{amount}} $t(метров, { "count": {{meters}} })',
    'по часовому поясу Europe/Moscow (UTC+03)': 'по часовому поясу Europe/Moscow (UTC+03)',
    'Можно активировать не более 10 геоточек': 'Можно активировать не более 10 геоточек',
    'Вступление в Клуб': 'Вступление в Клуб',
    'Дней в Клубе': 'Дней в Клубе',
    'Редактирование карты': 'Редактирование карты',
    'Создание карты': 'Создание карты',
    'Просмотр рассылки': 'Просмотр рассылки',
    'Баланс:': 'Баланс:',
    января: 'января',
    февраля: 'февраля',
    марта: 'марта',
    апреля: 'апреля',
    мая: 'мая',
    июня: 'июня',
    июля: 'июля',
    августа: 'августа',
    сентября: 'сентября',
    октября: 'октября',
    ноября: 'ноября',
    декабря: 'декабря',
    от: 'от',
    до: 'до',
    Включить: 'Включить',
    Исключить: 'Исключить',
    'Загружается...': 'Загружается...',
    'Можно указать несколько вариантов, по одному на строку': 'Можно указать несколько вариантов, по одному на строку',
    Входит: 'Входит',
    'Не входит': 'Не входит',
    'Загрузить еще...': 'Загрузить еще...',
    'Общие настройки': 'Общие настройки',
    'Настройки успешно сохранены': 'Настройки успешно сохранены',
    'Задайте любой Ваш вопрос службе поддержки:': 'Задайте любой Ваш вопрос службе поддержки:',
    'Сумма покупок за период. Учитываются только покупки с применением карт, исключая возвраты': 'Сумма покупок за период. Учитываются только покупки с применением карт, исключая возвраты',
    'Валовая прибыль с покупок за период. Учитываются только покупки с применением карт, исключая возвраты': 'Валовая прибыль с покупок за период. Учитываются только покупки с применением карт, исключая возвраты',
    'Первую покупку друга': 'Первую покупку друга',
    Номинал: 'Номинал',
    'Реферальный код': 'Реферальный код',
    'Реферальные коды': 'Реферальные коды',
    'К предыдущему периоду': 'К предыдущему периоду',
    'Расходы по программе': 'Расходы по программе',
    'Всего выдано карт': 'Всего выдано карт',
    'Процент применения карт': 'Процент применения карт',
    'Среднее значение за год': 'Среднее значение за год',
    и: 'и',
    'Сегменты клиентов': 'Сегменты клиентов',
    Округление: 'Округление',
    'Стоимость покупателя': 'Стоимость покупателя',
    Стоимость: 'Стоимость',
    'Цена до скидки': 'Цена до скидки',
    'За период': 'За период',
    'За все время': 'За все время',
    'Сгорело или отменено': 'Сгорело или отменено',
    'Качество клиентской базы': 'Качество клиентской базы',
    Ограничения: 'Ограничения',
    'Применим на': 'Применим на',
    'Можно не указывать одну из дат.': 'Можно не указывать одну из дат.',
    'Можно указывать не все значения.': 'Можно указывать не все значения.',
    'Можно не указывать одно из значений.': 'Можно не указывать одно из значений.',
    'Еще из': 'Еще из',
    'Кол-во:': 'Кол-во:',
    'Сумма покупок, сделанных клиентами в течение двух недель после получения рассылки. Если клиент получал несколько рассылок, то прибыль записывается последней. Учитываются только покупки с применением карт, включая возвраты': 'Сумма покупок, сделанных клиентами в течение двух недель после получения рассылки. Если клиент получал несколько рассылок, то прибыль записывается последней. Учитываются только покупки с применением карт, включая возвраты',
    'Сумма покупок, сделанных клиентами в течение двух недель после получения рассылки. Если клиент получал несколько рассылок, то выручка записывается последней. Учитываются только покупки с применением карт, включая возвраты': 'Сумма покупок, сделанных клиентами в течение двух недель после получения рассылки. Если клиент получал несколько рассылок, то выручка записывается последней. Учитываются только покупки с применением карт, включая возвраты',
    ИмяРебенкаКто: 'ИмяРебенкаКто',
    ИмяРебенкаКому: 'ИмяРебенкаКому',
    ИмяРебенкаКого: 'ИмяРебенкаКого',
    Сообщение: 'Сообщение',
    после: 'после',
    OFFSET_SIGN_AT: 'после',
    Фильтры: 'Фильтры',
    LANG_SWITCH: 'Switch to English',
    INTL_NUMBER: '{{n, number}}',
    INTL_DATE: '{{d, datetime}}',
    EMPTY_SEARCH: 'пусто',
    Сбросить: 'Сбросить',
    Метрики: 'Метрики',
    'Изменить метрику': 'Изменить метрику',
    Маркетинг: 'Маркетинг',
    'Фин. показатели': 'Фин. показатели',
    Чек: 'Чек',
    email: 'email',
    'Карты лояльности': 'Карты лояльности',
    Скидки: 'Скидки',
    'Скидки по ПЛ': 'Скидки по ПЛ',
    'Срок жизни клиента': 'Срок жизни клиента',
    'Процент повторных покупок': 'Процент повторных покупок',
    'Покупок на клиента': 'Покупок на клиента',
    'Выручка по ПЛ': 'Выручка по ПЛ',
    Наценка: 'Наценка',
    'Наценка по ПЛ': 'Наценка по ПЛ',
    'Средний чек по ПЛ': 'Средний чек по ПЛ',
    'Кол-во чеков': 'Кол-во чеков',
    'Кол-во чеков по ПЛ': 'Кол-во чеков по ПЛ',
    'Позиций на чек': 'Позиций на чек',
    'Позиций на чек по ПЛ': 'Позиций на чек по ПЛ',
    'Рост базы': 'Рост базы',
    Изменить: 'Изменить',
    'Только числа': 'Только числа',
    'Не более {{max}}': 'Не более {{max}}',
    'Не менее {{min}}': 'Не менее {{min}}',
    'Ошибка ввода': 'Ошибка ввода',
    не: 'не',
    'База знаний': 'База знаний',
    'по годам': 'по годам',
    'по месяцам': 'по месяцам',
    'по промокодам': 'по промокодам',
    'по торговым точкам': 'по торговым точкам',
    'по кассирам': 'по кассирам',
    'по рассылкам': 'по рассылкам',
    'по полу': 'по полу',
    'по сегментам': 'по сегментам',
    'по RFM сегментам': 'по RFM сегментам',
    CLIENTS_PANEL_HEADER: '{{amount}} $t(clients, { "count": {{count}} })',
    CLIENTS_WILL_BE_RECEIVED: '{{amount}} $t(clients, { "count": {{count}} })',
    SEGMENT_CLIENTS_DELETE: 'Удалить безвозвратно {{amount}} $t(clients, { "count": {{count}} }) сегмента\n{{segmentName}}?',
    CLIENTS: '{{amount}} $t(clients, { "count": {{count}} })',
    clients_one: 'клиент',
    clients_few: 'клиента',
    clients_many: 'клиентов',
    SEGMENTS_PANEL_HEADER: '{{amount}} $t(segments, { "count": {{count}} })',
    MAILING_RECIPIENTS_SEGMENTS: '{{amount}} $t(segments, { "count": {{count}} })',
    segments_one: 'сегмент',
    segments_few: 'сегмента',
    segments_many: 'сегментов',
    PURCHASES_PANEL_HEADER: '{{amount}} $t(purchases, { "count": {{count}} })',
    CLIENT_PURCHASES_COUNT: '{{amount}} $t(purchases, { "count": {{count}} })',
    purchases_one: 'чек',
    purchases_few: 'чека',
    purchases_many: 'чеков',
    CLIENT_RETURNS_COUNT: '{{amount}} $t(returns, { "count": {{count}} })',
    returns_one: 'возврат',
    returns_few: 'возврата',
    returns_many: 'возвратов',
    ROLE_FORM_MODAL_COUNTER: 'Назначена на {{amount}} $t(users_alt, { "count": {{count}} })',
    USERS_PANEL_HEADER: '{{amount}} $t(users, { "count": {{count}} })',
    'Роль ни на кого не назначена': 'Роль ни на кого не назначена',
    users_alt_one: 'пользователя',
    users_alt_few: 'пользователей',
    users_alt_many: 'пользователей',
    users_one: 'пользователь',
    users_few: 'пользователя',
    users_many: 'пользователей',
    Пользователя: 'Пользователя',
    'Удалить роль': 'Удалить роль',
    'только тех, кого пользователь добавил в систему сам': 'только тех, кого пользователь\nдобавил в систему сам',
    'Скрывает:': 'Скрывает:',
    'Email, кроме домена email': 'Email, кроме домена email',
    'Телефон, кроме последних цифр': 'Телефон, кроме последних цифр',
    Фамилию: 'Фамилию',
    'Карту, весь номер кроме последних цифр': 'Карту, весь номер кроме последних цифр',
    SHOPS_PANEL_HEADER: '{{amount}} $t(shops, { "count": {{count}} })',
    shops_one: 'точка продаж',
    shops_few: 'точки продаж',
    shops_many: 'точек продаж',
    млн: 'млн',
    тыс: 'тыс',
    'Данные сравниваются с предыдущим периодом': 'Данные сравниваются с предыдущим периодом',
    'Показать расчеты за': 'Показать расчеты за',
    'Расчеты за': 'Расчеты за',
    'Показывать отправленные за': 'Показывать отправленные за',
    'Показать покупки за': 'Показать покупки за',
    'Сбросить поиск': 'Сбросить поиск',
    'Название рассылки': 'Название рассылки',
    'Название акции': 'Название акции',
    'Название промокода': 'Название промокода',
    'Название кода': 'Название кода',
    'Ничего не найдено': 'Ничего не найдено',
    'Попробуйте изменить запрос': 'Попробуйте изменить запрос',
    'Название сегмента': 'Название сегмента',
    'Название подарочной карты': 'Название подарочной карты',
    'Сбросить фильтры': 'Сбросить фильтры',
    Превью: 'Превью',
    'Без ФИО': 'Без ФИО',
    MAILING_MODAL_HEADER: 'Статистика по рассылке {{name}}',
    'нет покупок': 'нет покупок',
    'нет возвратов': 'нет возвратов',
    'Без наименования': 'Без наименования',
    Детализация: 'Детализация',
    FIELD_MAX_LENGTH_ERROR: 'не более {{amount}} $t(symbols, { "count": {{count}} })',
    FIELD_MIN_LENGTH_ERROR: 'не менее {{amount}} $t(symbols, { "count": {{count}} })',
    'Не верный формат. Введите широту и долготу через пробел, запятую или точку с запятой': 'Не верный формат. Введите широту и долготу через пробел, запятую или точку с запятой',
    'широта, долгота': 'широта, долгота',
    GEO_PUSH_MESSAGE_PLACEHOLDER: 'Мы совсем рядом! В ТЦ Панорама, 4-й этаж, около кинотеатра. Открыты с 10 до 21',
    symbols_one: 'символа',
    symbols_few: 'символов',
    symbols_many: 'символов',
    FIELD_REQUIRED_ERROR: 'поле обязательно для ввода',
    FIELDS_REQUIRED_ERROR: 'поля обязательны для ввода',
    'Уникальный для каждого магазина.': 'Уникальный для каждого магазина.',
    Подробнее: 'Подробнее',
    'введите код': 'введите код',
    'введите название точки': 'введите название точки',
    'выберите регион': 'выберите регион',
    PRODUCT_CATALOG_FORM_LABEL: 'Каталог товаров',
    'выберите каталог': 'выберите каталог',
    'Создать точку': 'Создать точку',
    'Удалить точку': 'Удалить точку',
    'Для входа в систему': 'Для входа в систему',
    'только латиница': 'только латиница',
    'введите логин': 'введите логин',
    'введите пароль': 'введите пароль',
    сгенерировать: 'сгенерировать',
    'Скидка кассы': 'Скидка кассы',
    'Ручная скидка': 'Ручная скидка',
    'неверный Email': 'неверный Email',
    'отправить данные для входа на почту': 'отправить данные для входа на почту',
    'Для отображения в системе': 'Для отображения в системе',
    'введите имя пользователя': 'введите имя пользователя',
    'выберите роль': 'выберите роль',
    'Если введенные данные важны, сохраните их перед переходом на другую страницу': 'Если введенные данные важны, сохраните их перед переходом на другую страницу',
    'по правилам ПЛ': 'по правилам ПЛ',
    'Уйти без сохранения введенных данных?': 'Уйти без сохранения введенных данных?',
    'Если данные важны, сохраните их перед переходом на другую страницу': 'Если данные важны, сохраните их перед переходом на другую страницу',
    'Пойти сохранять': 'Пойти сохранять',
    'видят только администраторы': 'видят только администраторы',
    'введите комментарий': 'введите комментарий',
    'Удалить клиента {{name}}?': 'Удалить клиента\n{{name}}?',
    'Удалить покупку клиента {{clientName}}?': 'Удалить покупку клиента\n{{clientName}}?',
    'Удалить сегмент {{name}}?': 'Удалить сегмент\n{{name}}?',
    'Это действие необратимо.': 'Это действие необратимо.',
    'Удалить подарочную карту {{name}}?': 'Удалить подарочную\nкарту {{name}}?',
    'Удалить карту': 'Удалить карту',
    'Удалить пользователя {{name}}?': 'Удалить пользователя\n{{name}}?',
    'Удалить пользователя': 'Удалить пользователя',
    'Удалить роль {{name}}?': 'Удалить роль\n{{name}}?',
    'Удалить магазин {{name}}?': 'Удалить магазин {{name}}?',
    'Удалить магазин?': 'Удалить магазин?',
    'за каждого клиента сверх тарифа': 'за каждого клиента сверх тарифа',
    'Перенести продажи удаляемой точки в...': 'Перенести продажи удаляемой точки в...',
    'выберите точку продаж': 'выберите точку продаж',
    'Удалить рассылку {{name}}?': 'Удалить рассылку\n{{name}}?',
    'Удалить рассылку': 'Удалить рассылку',
    'Удалить промокод {{name}}?': 'Удалить промокод\n{{name}}?',
    'Удалить код приведи друга {{name}}?': 'Удалить код приведи друга\n{{name}}?',
    'Удалить промокод': 'Удалить промокод',
    'Удалить код': 'Удалить код',
    'Удалить акцию {{name}}?': 'Удалить акцию\n{{name}}?',
    'Удалить акцию': 'Удалить акцию',
    'Разблокировать подарочную карту {{name}} №{{n}}?': 'Разблокировать\nподарочную карту\n{{name}} №{{n}}?',
    'Разблокировать подарочную карту №{{n}}?': 'Разблокировать\nподарочную карту №{{n}}?',
    'Заблокировать подарочную карту {{name}} №{{n}}?': 'Заблокировать\nподарочную карту\n{{name}} №{{n}}?',
    'Заблокировать подарочную карту №{{n}}?': 'Заблокировать\nподарочную карту №{{n}}?',
    'Разблокировать карту': 'Разблокировать карту',
    'Заблокировать карту': 'Заблокировать карту',
    'Удалить отчет {{name}}?': 'Удалить отчет\n{{name}}?',
    'Удалить отчет': 'Удалить отчет',
    'Сохранить отчет': 'Сохранить отчет',
    'Личные данные': 'Личные данные',
    'Имя и Отчество': 'Имя и Отчество',
    NAME_PLACEHOLDER: 'Иван',
    MIDDLE_NAME_PLACEHOLDER: 'Иванович',
    LAST_NAME_PLACEHOLDER: 'Иванов',
    женский: 'женский',
    мужской: 'мужской',
    'не задан': 'не задан',
    'дд.мм.гггг': 'дд.мм.гггг',
    'введите номер': 'введите номер',
    'неверный номер телефона': 'неверный номер телефона',
    'выберите уровень': 'выберите уровень',
    'выберите место выдачи': 'выберите место выдачи',
    'Поместите курсор в данное поле и отсканируйте карту, номер карты отобразится в поле': 'Поместите курсор в данное поле и отсканируйте карту, номер карты отобразится в поле',
    'отсканируйте карту': 'отсканируйте карту',
    'неверный номер карты': 'неверный номер карты',
    CLIENT_PHONE_SUBSCRIBED_MESSAGE: 'согласен на<1/>SMS, Push и Viber-рассылку',
    CLIENT_EMAIL_SUBSCRIBED_MESSAGE: 'согласен на<1/>email-рассылку',
    'Дополнительные данные': 'Дополнительные данные',
    'введите имя ребенка': 'введите имя ребенка',
    'день рождения': 'день рождения',
    введите: 'введите',
    выберите: 'выберите',
    'рассылка на этот номер прекращена из-за ошибок доставки': 'рассылка на этот номер прекращена\nиз-за ошибок доставки',
    'рассылка на эту почту прекращена из-за ошибок доставки': 'рассылка на эту почту прекращена\nиз-за ошибок доставки',
    начислить: 'начислить',
    списать: 'списать',
    'введите число': 'введите число',
    'только числа': 'только числа',
    'Срок действия бонусов': 'Срок действия бонусов',
    'выберите срок действия': 'выберите срок действия',
    'например, почему бонусный баланс был изменен': 'например, почему бонусный баланс был изменен',
    'только для пользователей платформы, клиент его не видит': 'только для пользователей платформы, клиент его не видит',
    BONUSES_NOTIFY_PHONE_MESSAGE: 'Отправить клиенту<1/>SMS-уведомление\nо начислении бонусов<3/>',
    'Формат сообщения: Имя, вам начислено N бонусов (всего на счету N). Хорошего дня!': 'Формат сообщения: Имя, вам начислено N бонусов (всего на счету N). Хорошего дня!',
    'введите номер телефона, ФИО, Email или карту': 'введите номер телефона, ФИО, Email или карту',
    'Затраты на платформу': 'Затраты на платформу',
    'Затраты на рассылки': 'Затраты на рассылки',
    METRIC_TOOLTIP_revenue: 'Сумма всех покупок клиентов — по всем чекам.',
    METRIC_TOOLTIP_revenueProgram: 'Сумма всех покупок клиентов, участвующих в программе лояльности.',
    METRIC_TOOLTIP_expensesFull: 'Общие расходы на программу лояльности (скидки, бонусы, траты на рассылки, работа платформы).',
    METRIC_TOOLTIP_expensesPlatform: 'Стоимость обслуживания платформы и дополнительных модулей.',
    METRIC_TOOLTIP_expensesMailing: 'Стоимость отправленных рассылок по каналам (SMS, Email, Viber и т.д.).',
    METRIC_TOOLTIP_discounts: 'Сумма всех скидок, настроенных в MAXMA (промокоды, акции, бонусы) — по всем чекам.',
    METRIC_TOOLTIP_discountsProgram: 'Сумма всех скидок, настроенных в MAXMA (промокоды, акции, бонусы) — по чекам с применением карт лояльности.',
    METRIC_TOOLTIP_roi: '<span>Коэффициент возврата инвестиций (Return on Investment) по всем инструментам MAXMA. Чем больше %, тем окупаемость выше.</span><b>(Выручка − Расходы) ∕ Расходы × 100,</b><span>где Расходы = Затраты на абонплату, модули, рассылки + Скидки по промокодам, акциям, бонусам</span>',
    METRIC_TOOLTIP_avgSale: 'Среднее значение суммы покупки по всем чекам.',
    METRIC_TOOLTIP_avgSaleProgram: 'Среднее значение суммы покупки по всем чекам клиентов, участвующих в программе лояльности.',
    METRIC_TOOLTIP_avgItemCount: 'Среднее количество товаров или услуг по всем чекам.',
    METRIC_TOOLTIP_avgItemCountProgram: 'Среднее количество товаров или услуг в чеках клиентов, участвующих в программе лояльности.',
    METRIC_TOOLTIP_markup: 'Разница между выручкой и себестоимостью по всем чекам.',
    METRIC_TOOLTIP_markupProgram: 'Разница между выручкой и себестоимостью для клиентов, участвующих в программе лояльности.',
    METRIC_TOOLTIP_purchaseCount: 'Количество всех чеков.',
    METRIC_TOOLTIP_purchaseCountProgram: 'Количество чеков клиентов, участвующих в программе лояльности.',
    METRIC_TOOLTIP_repeatRate: 'Процент клиентов, сделавших 2+ покупки, среди всех покупателей.',
    METRIC_TOOLTIP_engagementRate: '<span>Доля чеков с примененной картой лояльности.</span><b>Кол-во чеков по ПЛ ∕ Кол-во чеков × 100%</b>',
    METRIC_TOOLTIP_bonusApplied: 'Количество списанных бонусов за выбранный период.',
    METRIC_TOOLTIP_bonusCollected: 'Количество начисленных бонусов за выбранный период.',
    METRIC_TOOLTIP_ltv: '<span>Средняя выручка, полученная от одного клиента за всё время (Lifetime Value).</span><b>LTV = Lifetime × ARPPU,</b><span>где не учитываются новички, вступившие за последние 30 дней</span>',
    METRIC_TOOLTIP_repeatInterval: '<span>Средний интервал между покупками клиентов. В расчете учитываются только клиенты, совершившие 2+ покупки.</span><b>Срок жизни клиента / Покупок на клиента</b>',
    METRIC_TOOLTIP_lifespan: 'Количество дней между первой и последней покупками, совершенными с применением карты (Lifetime). Новички, вступившие за последние 30 дней не учитываются.',
    METRIC_TOOLTIP_visitCount: 'Среднее количество покупок, совершаемое каждым клиентом, без учета новичков, вступивших за последние 30 дней.',
    METRIC_TOOLTIP_arppu: '<span>Average Revenue Per Paying User (ARPPU) — показатель средней выручки с одного покупателя за выбранный период.</span><b>ARPPU = Выручка / Количество платящих клиентов</b>',
    METRIC_TOOLTIP_signupCount: '<div>Количество созданных клиентских профилей.</div><div>Источники регистрации:</div><ul><li>выдача карт на кассе;</li><li>регистрация на сайте или приложении;</li><li>регистрация в сторонних сервисах, данные из которых передаются в MAXMA.</li></ul>',
    'Сгруппировать показатели метрик': 'Сгруппировать показатели метрик',
    'Выберите критерии группировки слева': 'Выберите критерии\nгруппировки слева',
    'Применить к отчету': 'Применить к отчету',
    'Вложенность групп': 'Вложенность групп',
    'перетащите строку чтобы поменять вложенность': 'перетащите строку чтобы поменять вложенность',
    'Редактирование отчета': 'Редактирование отчета',
    'введите название отчета': 'введите название отчета',
    'Переименовать отчет': 'Переименовать отчет',
    'введите сумму': 'введите сумму',
    'Выставить счет': 'Выставить счет',
    'Подтвердить подписку': 'Подтвердить подписку',
    'Открыть в браузере': 'Открыть в браузере',
    'имя клиента не распределено по полям:': 'имя клиента не распределено по полям:\n',
    'выберите место покупки': 'выберите место покупки',
    'Указать сумму': 'Указать сумму',
    MIN_ERROR_MESSAGE: 'не менее {{amount}}',
    MAX_ERROR_MESSAGE: 'не более {{amount}}',
    INVALID_FORMAT_ERROR_MESSAGE: 'неверный формат',
    'введите промокод': 'введите промокод',
    применить: 'применить',
    'Промокод не найден': 'Промокод не найден',
    'Условия промокода не выполнены': 'Условия промокода не выполнены',
    'выберите товар': 'выберите товар',
    'введите название сегмента': 'введите название сегмента',
    'Цвет сегмента': 'Цвет сегмента',
    'Количество карт': 'Количество карт',
    'введите количество карт': 'введите количество карт',
    'Сгенерировать и выгрузить': 'Сгенерировать и выгрузить',
    Даты: 'Даты',
    'последнюю неделю': 'последнюю неделю',
    'последний месяц': 'последний месяц',
    'последний квартал': 'последний квартал',
    'последний год': 'последний год',
    'введите название карты': 'введите название карты',
    'введите идентификатор': 'введите идентификатор',
    'Действует в даты c': 'Действует в даты c',
    по: 'по',
    'введите срок действия': 'введите срок действия',
    'Для печатной карты': 'Для печатной карты',
    'введите товарный код': 'введите товарный код',
    'в формате EAN-13': 'в формате EAN-13',
    'введите артикул': 'введите артикул',
    'Тип уникального кода': 'Тип уникального кода',
    'Префикс уникального кода': 'Префикс уникального кода',
    'введите префикс': 'введите префикс',
    PRINTABLE_CARD_HELP: '<0>Подсказки по настройке</0><1>\nпечатной карты</1>',
    'Выберите категорию фильтра слева': 'Выберите категорию\nфильтра слева',
    день: 'день',
    месяц: 'месяц',
    год: 'год',
    'Укажите хотя бы одно значение': 'Укажите хотя бы одно значение',
    'введите список': 'введите список',
    кроме: 'кроме',
    лет: 'лет',
    'Добавить точку продаж': 'Добавить точку продаж',
    'Активировать опцию «Обещанный платеж» на 48 часов': 'Активировать опцию «Обещанный платеж» на 48 часов',
    'Работа сервиса приостановлена': 'Работа сервиса приостановлена',
    'Работа сервиса активирована с опцией Обещанный платеж.': 'Работа сервиса активирована с опцией Обещанный платеж.',
    'К пополнению баланса': 'К пополнению баланса',
    SHARED_PROMOCODE_TYPE: 'Общий',
    PERSONAL_PROMOCODE_TYPE: 'Персональный',
    'Для передачи кода в рассылке или другими способами лично': 'Для передачи кода в рассылке или другими способами лично',
    'Генерируемая часть кода добавляется к префиксу': 'Генерируемая часть кода добавляется к префиксу',
    'Период действия с': 'Период действия с',
    'Условия применения': 'Условия применения',
    'при сумме покупки от': 'при сумме покупки от',
    'в месте продажи': 'в месте продажи',
    'на товар': 'на товар',
    'Выдается покупателю, который передает его другу': 'Выдается покупателю, который передает его другу',
    'Друг применяет промокод': 'Друг применяет промокод',
    'Вознаграждение другу': 'Вознаграждение другу',
    'Вознаграждение рекомендателю': 'Вознаграждение рекомендателю',
    PROMOCODE_REFERRER_REWARDS_INFO: 'Бонусы начисляются через <1>1 день</1> после покупки друга',
    PROMOCODE_REFERRER_REWARDS_INFO_few: 'Бонусы начисляются через <1>{{count}} дня</1> после покупки друга',
    PROMOCODE_REFERRER_REWARDS_INFO_many: 'Бонусы начисляются через <1>{{count}} дней</1> после покупки друга',
    'Рекомендатель получает': 'Рекомендатель получает',
    'начислять за': 'начислять за',
    REWARD_NOTIFY_PHONE_MESSAGE: 'Уведомить о начислении бонусов<1/> за покупки друга (SMS)',
    'Формат сообщения: «Имя, вам начислено N бонусов (всего на счету Y) за покупку друга по вашему промокоду: Префикс кода. Хорошего дня!»': 'Формат сообщения: «Имя, вам начислено N бонусов (всего на счету Y) за покупку друга по вашему промокоду: Префикс кода. Хорошего дня!»',
    'в любой день': 'в любой день',
    'в любой': 'в любой',
    'в любую': 'в любую',
    'в любое': 'в любое',
    'в любые': 'в любые',
    'введите название': 'введите название',
    'рабочее название акции в платформе, клиент его не видит': 'рабочее название акции в платформе, клиент его не видит',
    Выбрать: 'Выбрать',
    'начисляются за': 'начисляются за',
    'Клиенты с картами': 'Клиенты с картами',
    'Условия акции': 'Условия акции',
    'Условия по покупке': 'Условия по покупке',
    'действует на': 'действует на',
    чек: 'чек',
    'любой товар': 'любой товар',
    'указанный товар': 'указанный товар',
    'Выберите товар': 'Выберите товар',
    'Ограничения акции': 'Ограничения акции',
    'Принцип работы': 'Принцип работы',
    'выберите принцип': 'выберите принцип',
    'если применилась такая акция, к чеку не применится никакая другая': 'если применилась такая акция, к чеку не применится никакая другая',
    'если к строке чека применилась такая акция, акция с типом «уступает другим» к этой строке не применится': 'если к строке чека применилась такая акция, акция с типом «уступает другим» к этой строке не применится',
    'применяется на те строки чека, на которые не применена акция «вытесняет другие»': 'применяется на те строки чека, на которые не применена акция «вытесняет другие»',
    'применяется к строке, только если дает большую скидку (или большее количество бонусов), чем уже примененная к этой строке акция': 'применяется к строке, только если дает большую скидку (или большее количество бонусов), чем уже примененная к этой строке акция',
    'применяется к строке, даже если к той же строке уже применены другие акции': 'применяется к строке, даже если к той же строке уже применены другие акции',
    'Клиент участвует': 'Клиент участвует',
    'неограниченное количество раз': 'неограниченное количество раз',
    OFFER_ARB_TYPE_HELP: 'Правила комбинации при встрече с другими акциями в чеке. <2>Подробнее тут</2>',
    GROWING_DISCOUNT_START: 'на {{start}}-й товар',
    GROWING_DISCOUNT_RANGE: 'с {{start}} по {{end}} товар',
    GROWING_DISCOUNT_FURTHER: 'на {{n}}-й и далее',
    'Не забудьте протестировать акцию на кассе': 'Не забудьте протестировать акцию на кассе',
    'Добавить товар': 'Добавить товар',
    товар: 'товар',
    'все указанные товары': 'все указанные товары',
    NO_MORE_TIMES: '$t(no_more_times, { "count": {{count}} })',
    no_more_times_one: 'раза за',
    no_more_times_few: 'раз за',
    no_more_times_many: 'раз за',
    TIMES: '$t(times, { "count": {{count}} })',
    times_one: 'раз',
    times_few: 'раза',
    times_many: 'раз',
    'Для настройки данного модуля обратитесь к менеджеру MAXMA': 'Для настройки данного модуля\nобратитесь к менеджеру MAXMA',
    'Viber отправится только тем, кому не дойдет Push': 'Viber отправится только тем, кому не дойдет Push',
    'SMS отправится только тем, кому не дойдет Push и Viber': 'SMS отправится только тем, кому не дойдет Push и Viber',
    'SMS отправится только тем, кому не дойдет Push': 'SMS отправится только тем, кому не дойдет Push',
    'SMS отправится только тем, кому не дойдет Viber': 'SMS отправится только тем, кому не дойдет Viber',
    'Email отправится всем': 'Email отправится всем',
    'Viber отправится тем, кому не дойдет Push': 'Viber отправится тем,\nкому не дойдет Push',
    'SMS отправится тем, кому не дойдет Push и Viber': 'SMS отправится тем,\nкому не дойдет Push и Viber',
    'SMS отправится тем, кому не дойдет Push': 'SMS отправится тем,\nкому не дойдет Push',
    'SMS отправится тем, кому не дойдет Viber': 'SMS отправится тем,\nкому не дойдет Viber',
    OFFSET_VALUE_MIN: '$t(offset_value_min, { "count": {{offset}} })',
    offset_value_min_one: 'минуту',
    offset_value_min_few: 'минуты',
    offset_value_min_many: 'минут',
    OFFSET_VALUE_HOUR: '$t(offset_value_hour, { "count": {{offset}} })',
    offset_value_hour_one: 'час',
    offset_value_hour_few: 'часа',
    offset_value_hour_many: 'часов',
    OFFSET_VALUE_DAY: '$t(offset_value_day, { "count": {{offset}} })',
    offset_value_day_one: 'день',
    offset_value_day_few: 'дня',
    offset_value_day_many: 'дней',
    OFFSET_VALUE_MONTH: '$t(offset_value_month, { "count": {{offset}} })',
    offset_value_month_one: 'месяц',
    offset_value_month_few: 'месяца',
    offset_value_month_many: 'месяцев',
    'Выберите тип поощрения слева': 'Выберите тип поощрения слева',
    'Выберите акцию': 'Выберите акцию',
    'Выберите промокод': 'Выберите промокод',
    'Выберите карту': 'Выберите карту',
    BONUSES_EXPIRING: '$t(bonuses_expiring, { "count": {{count}} })',
    bonuses_expiring_one: 'бонус, сгорает через',
    bonuses_expiring_few: 'бонуса, сгорают через',
    bonuses_expiring_many: 'бонусов, сгорают через',
    DAYS_EXPIRING: '$t(days_expiring, { "count": {{count}} })',
    days_expiring_one: 'день',
    days_expiring_few: 'дня',
    days_expiring_many: 'дней',
    DAYS_EXPIRING_AFTER: 'через {{count}} $t(days_expiring, { "count": {{count}} })',
    действует: 'действует',
    MAILING_VALID_DAYS: '$t(mailing_valid_days, { "count": {{count}} })',
    mailing_valid_days_one: 'день с момента рассылки',
    mailing_valid_days_few: 'дня с момента рассылки',
    mailing_valid_days_many: 'дней с момента рассылки',
    'введите название рассылки': 'введите название рассылки',
    'рабочее название рассылки в платформе, клиент его не видит': 'рабочее название рассылки в платформе, клиент его не видит',
    'Согласные на рассылку': 'Согласные на рассылку',
    'Условия отправки': 'Условия отправки',
    'Событие, при котором рассылка автоматически отправится': 'Событие, при котором рассылка автоматически отправится',
    если: 'если',
    'отправлять при каждом наступлении события': 'отправлять при каждом наступлении события',
    'Поощрения внутри рассылки': 'Поощрения внутри рассылки',
    'Бонусы, акции, промокоды, подарочные карты': 'Бонусы, акции, промокоды, подарочные карты',
    MAILINGS: '{{count}} $t(mailing, { "count": {{count}} })',
    MAILINGS_SELECTED: 'Выбрано {{count}} $t(mailing, { "count": {{count}} })',
    mailing_one: 'рассылка',
    mailing_few: 'рассылки',
    mailing_many: 'рассылок',
    GIFT_CARDS: '{{count}} $t(giftCards, { "count": {{count}} })',
    giftCards_one: 'подарочная карта',
    giftCards_few: 'подарочные карты',
    giftCards_many: 'подарочных карт',
    'Считать достижением цели': 'Считать достижением цели',
    'Отобразится в таблицах как CR (Conversion Rate)': 'Отобразится в таблицах как CR (Conversion Rate)',
    'Дата отправки': 'Дата отправки',
    SEND_FOR: 'на',
    SEND_AT: 'в',
    'по часовому поясу': 'по часовому поясу',
    'Прогнозируемые затраты': 'Прогнозируемые затраты',
    COST_UP_TO: 'до',
    'в неделю': 'в неделю',
    Получат: 'Получат',
    Персонализация: 'Персонализация',
    'Выберите параметр': 'Выберите параметр',
    'При отправке вместо него подставятся конкретные данные для клиента': 'При отправке вместо него подставятся конкретные данные для клиента',
    клиент: 'клиент',
    ребенок: 'ребенок',
    промокод: 'промокод',
    'ссылка на эл.кошелек': 'ссылка на эл.кошелек',
    'код подарочной карты': 'код подарочной карты',
    CUSTOMER_NAME_PARAMETER: 'Имя',
    CUSTOMER_FULLNAME_PARAMETER: 'Имя и Отчество',
    CUSTOMER_LASTNAME_PARAMETER: 'Фамилия',
    CUSTOMER_MIDDLENAME_PARAMETER: 'Отчество',
    CUSTOMER_PARAMETER: 'Имя Отчество Фамилия',
    'Имя и Отчество клиента': 'Имя и Отчество клиента',
    'Фамилия клиента': 'Фамилия клиента',
    'Отчество клиента': 'Отчество клиента',
    'Имя ребенка в им. падеже (кто?)': 'Имя ребенка в им. падеже (кто?)',
    'Имя ребенка в дат. падеже (кому?)': 'Имя ребенка в дат. падеже (кому?)',
    'Имя ребенка в винит. падеже (кого?)': 'Имя ребенка в винит. падеже (кого?)',
    'сгорает бонусов': 'сгорает бонусов',
    'всего бонусов': 'всего бонусов',
    'ближайшая дата сгорания бонусов': 'ближайшая дата сгорания бонусов',
    'начислено бонусов за покупку': 'начислено бонусов за покупку',
    'Начислено бонусов': 'Начислено бонусов',
    'Сумма после скидок': 'Сумма после скидок',
    'дата сгорания бонусов за покупку': 'дата сгорания бонусов за покупку',
    'сумма покупок до следующего уровня': 'сумма покупок до следующего уровня',
    'отменено бонусов за возврат': 'отменено бонусов за возврат',
    'начислено бонусов поощрения': 'начислено бонусов поощрения',
    'дата сгорания бонусов поощрения': 'дата сгорания бонусов поощрения',
    'название товара': 'название товара',
    'картинка товара': 'картинка товара',
    'старая цена товара': 'старая цена товара',
    'цена товара': 'цена товара',
    'ссылка на товар': 'ссылка на товар',
    'Пример отображения': 'Пример отображения',
    'если в этом году': 'если в этом году',
    'если в следующем году': 'если в следующем году',
    VAR_SAMPLE_CUSTOMER_NAME: 'Виктория',
    VAR_SAMPLE_CUSTOMER_FULLNAME: 'Виктория Валерьевна',
    VAR_SAMPLE_CUSTOMER_LASTNAME: 'Минаева',
    VAR_SAMPLE_CUSTOMER_MIDDLENAME: 'Валерьевна',
    VAR_SAMPLE_CUSTOMER: 'Виктория Валерьевна Минаева',
    VAR_SAMPLE_CHILD_NAME: 'Катя',
    VAR_SAMPLE_PRODUCT_TITLE: 'Комбинезон детский',
    VAR_SAMPLE_LEVEL: 'Первый уровень',
    'Как выглядит Push': 'Как выглядит Push',
    'введите сообщение': 'введите сообщение',
    'Любые символы юникода (кириллица тоже). Рекомендуемая длина < 80 символов': 'Любые символы юникода (кириллица тоже). Рекомендуемая длина < 80 символов',
    'Картинка на Wallet-карте': 'Картинка на Wallet-карте',
    'Показывать картинку': 'Показывать картинку',
    'Отправить тестовый PUSH': 'Отправить тестовый PUSH',
    'Укажите номер телефона': 'Укажите номер телефона',
    'Можете указать несколько адресов, по одному на каждую строку': 'Можете указать несколько адресов, по одному на каждую строку',
    'Можете указать несколько номеров, по одному на каждую строку, в любом формате': 'Можете указать несколько номеров, по одному на каждую строку, в любом формате',
    'Можете указать несколько номеров, по одному на каждую строку, в любом формате. Номер должен быть в базе клиентов, и у него должна быть установлена карта Wallet': 'Можете указать несколько номеров, по одному на каждую строку, в любом формате. Номер должен быть в базе клиентов, и у него должна быть установлена карта Wallet',
    'Укажите email': 'Укажите email',
    'Любые символы юникода (кириллица тоже). Рекомендуемая длина < 400 символов': 'Любые символы юникода (кириллица тоже). Рекомендуемая длина < 400 символов',
    Картинка: 'Картинка',
    'Квадратная, до 300кб': 'Квадратная, до 300кб',
    Кнопка: 'Кнопка',
    'введите текст кнопки': 'введите текст кнопки',
    'Неверный формат': 'Неверный формат',
    'ссылка из кнопки и/или картинки': 'ссылка из кнопки и/или картинки',
    'Отправить тестовый Viber': 'Отправить тестовый Viber',
    'Любые символы юникода (кириллица тоже). Рекомендуемая длина < 6 SMS': 'Любые символы юникода (кириллица тоже). Рекомендуемая длина < 6 SMS',
    'Отправить тестовое SMS': 'Отправить тестовое SMS',
    'введите тему письма': 'введите тему письма',
    PRODUCTS_IN_BLOCK: '$t(products, { "count": {{count}} }) в блоке',
    products_one: 'товар',
    products_few: 'товара',
    products_many: 'товаров',
    PRODUCTS_IN_BLOCK_HELP: 'В конечном письме в блоке будет $t(products_help, { "count": {{count}} })',
    products_help_one: 'отображен {{count}} товар',
    products_help_few: 'отображено {{count}} товара',
    products_help_many: 'отображено {{count}} товаров',
    Вставлять: 'Вставлять',
    'Добавить товарные рекомендации': 'Добавить товарные рекомендации',
    'В редакторе будет блок «Рекомендуемые товары»': 'В редакторе будет блок «Рекомендуемые товары»',
    'Отправить тестовое письмо': 'Отправить тестовое письмо',
    'Тестовый Email': 'Тестовый Email',
    'Длина:': 'Длина:',
    'Выбрать дизайн или создать новый': 'Выбрать дизайн\nили создать новый',
    'Удалить дизайн и содержимое письма?': 'Удалить дизайн\nи содержимое письма?',
    'Выберите шаблон письма': 'Выберите шаблон письма',
    'Созданные вами': 'Созданные вами',
    Все: 'Все',
    'Без названия': 'Без названия',
    'Собрать цепочку': 'Собрать цепочку',
    'без названия': 'без названия',
    'С чистого листа': 'С чистого листа',
    'У вас остался несохраненный дизайн. Восстановить его?': 'У вас остался несохраненный дизайн. Восстановить его?',
    Восстановить: 'Восстановить',
    'Обновление...': 'Обновление...',
    'Ошибка загрузки изображения': 'Ошибка загрузки изображения',
    'Задайте получателей рассылки': 'Задайте получателей рассылки',
    'Задайте необходимые критерии': 'Задайте необходимые критерии',
    Совет: 'Совет',
    Лояльность: 'Лояльность',
    'регистрации в ПЛ': 'регистрации в ПЛ',
    'установки карты Wallet': 'установки карты Wallet',
    'подписки на Email-рассылку': 'подписки на Email-рассылку',
    'получения Email-рассылки': 'получения Email-рассылки',
    Рекомендации: 'Рекомендации',
    FORM_MAILING_PROMOCODE_WARNING: 'Не забудьте вставить параметр <2>промокод</2> в',
    FORM_MAILING_GIFT_CARD_WARNING: 'Не забудьте вставить параметр <2>код подарочной карты</2> в',
    FORM_MAILING_BONUSES_WARNING: 'Исключите из получателей клиентов с нулевым балансом, чтобы не уведомлять их при использовании параметра <2>всего бонусов</2>',
    'Блок товаров из брошенной корзины': 'Блок товаров из брошенной корзины',
    'Блок товаров со сниженной ценой в избранном': 'Блок товаров со сниженной ценой в избранном',
    'Блок товаров со сниженной ценой в корзине': 'Блок товаров со сниженной ценой в корзине',
    'Блок с товарными рекомендациями': 'Блок с товарными рекомендациями',
    'Показывать в блоке': 'Показывать в блоке',
    'Добавьте блок в тело письма в редакторе': 'Добавьте блок в тело письма в редакторе',
    NEW_PRODUCTS: '$t(new_products, { "count": {{count}} })',
    new_products_one: 'новинку',
    new_products_few: 'новинки',
    new_products_many: 'новинок',
    LAST_VIEWED_PRODUCTS: '$t(last_viewed_products, { "count": {{count}} })',
    last_viewed_products_one: 'последний просмотренный товар',
    last_viewed_products_few: 'последних просмотренных товара',
    last_viewed_products_many: 'последних просмотренных товаров',
    BESTSELLERS: '$t(bestsellers, { "count": {{count}} })',
    bestsellers_one: 'бестселлер',
    bestsellers_few: 'бестселлера',
    bestsellers_many: 'бестселлеров',
    'по расписанию': 'по расписанию',
    'каждый день': 'каждый день',
    'каждую неделю': 'каждую неделю',
    'каждый месяц': 'каждый месяц',
    'каждый год': 'каждый год',
    SEND_ON_DAYS: 'по',
    'числа в': 'числа в',
    'последний день месяца': 'последний день месяца',
    'достижения нового уровня': 'достижения нового уровня',
    'Сумма покупок до следующего уровня ПЛ': 'Сумма покупок до следующего уровня ПЛ',
    'до следующего уровня ПЛ': 'до следующего уровня ПЛ',
    'покупка от': 'покупка от',
    'покупка до': 'покупка до',
    'не открыли письмо': 'не открыли письмо',
    'не перешли по ссылке в письме': 'не перешли по ссылке в письме',
    'не купили после перехода по ссылке': 'не купили после перехода по ссылке',
    'выберите рассылку': 'выберите рассылку',
    Шаблоны: 'Шаблоны',
    'введите количество кодов': 'введите количество кодов',
    DAYS_RANGE: '{{amount}} $t(days_range, { "count": {{count}} })',
    days_range_one: 'дня',
    days_range_few: 'дней',
    days_range_many: 'дней',
    VIP: 'VIP',
    'Потенциальные VIP': 'Потенциальные VIP',
    Лояльные: 'Лояльные',
    Новички: 'Новички',
    'Уходящие VIP': 'Уходящие VIP',
    'Потенциально лояльные': 'Потенциально\nлояльные',
    'Возможный отток': 'Возможный\nотток',
    'VIP отток': 'VIP отток',
    Отток: 'Отток',
    'Разовые клиенты': 'Разовые\nклиенты',
    Ультраотток: 'Ультраотток',
    Большая: 'Большая',
    Средняя: 'Средняя',
    Маленькая: 'Маленькая',
    Недавно: 'Недавно',
    Давно: 'Давно',
    'Очень давно': 'Очень давно',
    Редко: 'Редко',
    Иногда: 'Иногда',
    Часто: 'Часто',
    'Таблица RFM в кол-ве клиентов': 'Таблица RFM в кол-ве клиентов',
    Автопороги: 'Автопороги',
    RFM_THRESHOLDS_TOOLTIP: 'Автопороги обновляются раз в месяц.\n<2>Про расчет порогов в базе знаний</2>',
    Всего: 'Всего',
    RFM_CHART_CLIENTS: 'Количество клиентов',
    RFM_CHART_PURCHASES: 'Количество покупок',
    RFM_CHART_REVENUE: 'Выручка',
    RFM_CHART_PROFIT: 'Прибыль',
    RFM_CHART_ARPPU: 'ARPPU',
    RFM_CHART_AVG_SALE: 'Средний чек',
    'Создать...': 'Создать...',
    'Посмотреть клиентов': 'Посмотреть клиентов',
    'ручную рассылку': 'ручную рассылку',
    авторассылку: 'авторассылку',
    'Ультрабольшие чеки': 'Ультрабольшие чеки',
    'Ультраактивные покупатели': 'Ультраактивные покупатели',
    'Возможный фрод': 'Возможный фрод',
    Оптовики: 'Оптовики',
    RFM_SEGMENT_DESCRIPTION_VIP: 'Суперлояльные клиенты VIP много покупают, а последнюю покупку совершили недавно. Их важно удерживать, мотивировать в увеличение показателей по покупкам. Основные задачи по работе: удержать и увеличить объем группы за счет повышения уровня лояльности остальной клиентской базы, а также продолжать улучшать показатели по покупкам.',
    RFM_SEGMENT_DESCRIPTION_potentialVIP: 'Потенциально суперлояльные клиенты, которые близки к переходу в группу суперлояльных VIP-клиентов. Они также положительно относятся к бренду, много покупают, а последний чек совершили недавно. Точка роста — маленькая и средняя сумма покупок, недостаточная для определения в VIP. Таких клиентов важно замотивировать увеличить сумму покупок за счет увеличения среднего чека или частотности посещений.',
    RFM_SEGMENT_DESCRIPTION_loyal: 'Лояльные клиенты бренда. Точки роста: среднее количество покупок и средняя или небольшая сумма. В перспективе могут стать суперлояльными VIP при условии, что либо будут совершать повторные покупки с ростом среднего чека, либо кратно увеличат частотность совершения покупок при своем стандартном чеке. Таких клиентов важно замотивировать совершать повторные покупки с ростом суммы транзакций.',
    RFM_SEGMENT_DESCRIPTION_newcomers: 'Новые клиенты бренда, недавно вступившие в программу лояльности и совершившие покупку. Точка роста: еще маленькое количество покупок. Могут стать лояльными и суперлояльными VIP-клиентами, если продолжат совершать покупки с регулярной частотой. При регистрации важно поощрить за участие в программе лояльности (welcome-сообщение) — для совершения второй покупки. Далее таких клиентов нужно познакомить с брендом и замотивировать совершать следующие повторные покупки.',
    RFM_SEGMENT_DESCRIPTION_leavingVIP: 'Суперлояльные клиенты VIP, которые относительно давно не совершали покупку. Особенность: либо у клиентов низкая частотность покупок при сохранении лояльности, либо клиенты изменили свое покупательское поведение с риском ухода в отток и потери лояльности. Для возвращения к бренду важно замотивировать совершить повторную покупку. Главная задача — вернуть в группу VIP за счет интересного предложения и коммуникации с поощрением на следующую покупку.',
    RFM_SEGMENT_DESCRIPTION_potentialLoyal: 'Потенциально лояльные клиенты бренда, которые относительно давно не совершали покупок. Особенность: либо у клиентов низкая частотность покупок при сохранении лояльности, либо клиенты изменили свое покупательское поведение с риском ухода в отток и потери лояльности. В перспективе могут стать группой лояльных при условии, что увеличат частотность совершения покупок. Главная задача — вернуть в группу лояльных за счет интересного предложения и коммуникации с поощрением на следующую покупку.',
    RFM_SEGMENT_DESCRIPTION_outflowPossible: 'Новые клиенты бренда, которые находятся в риске попадания в отток из-за давности совершения последней покупки. Точки роста: еще маленькое количество покупок и давность совершения последней из них. В перспективе могут стать лояльными при условии, что увеличат частотность совершения покупок. Главная задача — вернуть и перевести в группу лояльных за счет интересного предложения и коммуникации с поощрением на следующую покупку.',
    RFM_SEGMENT_DESCRIPTION_outflowVIP: 'Суперлояльные клиенты VIP, которые давно не совершали покупку и считаются оттоком. Особенность: клиенты ранее были ультралояльны к бренду, но на данный момент изменили свое покупательское поведение с уходом в отток и потерей лояльности. Для возвращения к бренду важно замотивировать совершить повторную покупку. Главная задача — вернуть в группу VIP в рамках реактивационных мероприятий за счет интересного предложения и коммуникации с большим поощрением на следующую покупку. При отсутствии реакции на цепочку сообщений — убрать из коммуникаций.',
    RFM_SEGMENT_DESCRIPTION_outflow: 'Лояльные клиенты, которые давно не совершали покупку и считаются оттоком. Особенность: ранее клиенты были лояльны к бренду, совершая повторные покупки, но на данный момент изменили свое покупательское поведение с уходом в отток и потерей лояльности. Для возвращения к бренду важно замотивировать совершить повторную покупку. Главная задача — вернуть в группу лояльных или VIP в рамках реактивационных мероприятий за счет интересного предложения и коммуникации со средним поощрением на следующую покупку. При отсутствии реакции на цепочку сообщений — убрать из коммуникаций.',
    RFM_SEGMENT_DESCRIPTION_oneTimeClients: 'Новые клиенты бренда, которые очень давно не совершали повторную покупку и считаются оттоком. Могут оказаться разовыми посетителями по причинам, не зависящим от бренда. Главная задача — попробовать вернуть с небольшим количеством касаний в качестве реактивационных мероприятий с минимальным поощрением за счет интересного предложения на следующую покупку. При отсутствии реакции на цепочку сообщений — убрать из коммуникаций.',
    RFM_SEGMENT_DESCRIPTION_ultraflow: 'Клиенты, которые совершили последнюю покупку слишком давно.',
    RFM_SEGMENT_DESCRIPTION_ultraLargeSales: 'Клиенты, которые совершают покупки с высоким средним чеком — нестандартным для основной базы. Для работы с клиентами этой группы используются рекомендации в соответствии с присвоенным сегментом RFM.',
    RFM_SEGMENT_DESCRIPTION_ultraActiveCustomers: 'Клиенты, которые совершили большое количество чеков — нестандартное для основной базы. Для работы с клиентами этой группы рекомендуется выделение отдельного сегмента и использование механик на повышение среднего чека.',
    RFM_SEGMENT_DESCRIPTION_possibleFraud: 'Клиенты, которые совершили большое количество покупок на крупную сумму с небольшим интервалом между чеками — в отличие от основной базы клиентов. Данная аномалия считается риском проявления фрода. Рекомендуется заблокировать профиль клиента на платформе и проверить в СБ. При отсутствии мошеннических действий — считать VIP-клиентом и использовать рекомендации в соответствии с RFM-анализом.',
    RFM_SEGMENT_DESCRIPTION_wholesalers: 'Клиенты, которые совершили большое количество чеков на большую сумму относительно характеристик основной базы — поведение считается схожим с оптовыми покупателями. Для работы с клиентами этой группы рекомендуется выделение отдельного сегмента и использование механик на удержание внутри сегмента с увеличением частоты покупок, а также разработка персональных акций и предложений в зависимости от особенностей покупаемого товара и объемов закупки.',
    'настройте автоматическую рассылку на день рождения клиента': 'настройте автоматическую рассылку на день рождения клиента',
    'используйте ручные рекламные и информационные рассылки (новая коллекция, распродажи, информация о бренде и др.)': 'используйте ручные рекламные и информационные рассылки (новая коллекция, распродажи, информация о бренде и др.)',
    'Исключите сегмент из ручных рекламных и информационных рассылок': 'Исключите сегмент из ручных рекламных и информационных рассылок',
    SET_UP_AUTO_MAILINGS: 'Настройте {{count}} $t(auto_mailing, { "count": {{count}} })',
    auto_mailing_one: 'автоматическая рассылка',
    auto_mailing_few: 'автоматические рассылки',
    auto_mailing_many: 'автоматических рассылок',
    Дополнительно: 'Дополнительно',
    BURNING_REWARD_REMINDER: 'напоминание о сгорании поощрения через {{count}} $t(days_expiring, { "count": {{count}} })',
    USE_REWARD_MESSAGE: 'Используйте {{rewardType}} поощрение, сгорающее через {{count}} $t(days_expiring, { "count": {{count}} }) после отправки.',
    среднее: 'среднее',
    'минимальное или среднее': 'минимальное или среднее',
    минимальное: 'минимальное',
    крупное: 'крупное',
    'среднее или крупное': 'среднее или крупное',
    MOVE_TO_THE_SEGMENT: 'при переходе клиента в сегмент «{{segmentName}}»',
    IN_THE_SEGMENT: 'при нахождении в сегменте «{{segmentName}}» {{count}} $t(days_expiring, { "count": {{count}} })',
    IN_THE_SEGMENT_WITH_REPEAT: 'при нахождении в сегменте «{{segmentName}}» {{count}} $t(days_expiring, { "count": {{count}} }) с повтором отправки раз в {{countRepeat}} $t(days_expiring, { "count": {{countRepeat}} })',
    'Настройте welcome-рассылку': 'Настройте welcome-рассылку',
    'при регистрации клиента с приветствием от бренда и подарочным начислением': 'при регистрации клиента с приветствием от бренда и подарочным начислением',
    'Механики для увеличения частоты покупок (акции с предложением, ограниченным по времени)': 'Механики для увеличения частоты покупок (акции с предложением, ограниченным по времени)',
    'Информационные рассылки': 'Информационные рассылки',
    'Стандартные автоматические рассылки: день рождения и праздники': 'Стандартные автоматические рассылки: день рождения и праздники',
    'Рекомендации в соответствии с присвоенным маркером RFM': 'Рекомендации в соответствии с присвоенным маркером RFM',
    'Механики для увеличения среднего чека (предложение акций 1+1, комплементарных предложений и т.д.)': 'Механики для увеличения среднего чека (предложение акций 1+1, комплементарных предложений и т.д.)',
    'Блокировка пользователей, исключение из всех рассылок и передача на проверку в СБ': 'Блокировка пользователей, исключение из всех рассылок и передача на проверку в СБ',
    'При ошибке — возвращение в сегмент VIP': 'При ошибке — возвращение в сегмент «VIP»',
    'Изучение сегмента, разработка персональных акций': 'Изучение сегмента, разработка персональных акций',
    'Стандартные рекламные рассылки: с выгодным предложением / акцией / новостью о новом товаре или интересной товарной группе': 'Стандартные рекламные рассылки: с выгодным предложением / акцией / новостью о новом товаре или интересной товарной группе',
    'Смотреть метрики в отчете': 'Смотреть метрики в отчете',
    'А вы знали, что работа с RFM-сегментами поможет достичь конверсии до 80%?': 'А вы знали, что работа с RFM-сегментами поможет достичь конверсии до 80%?',
    'Протестируйте бесплатно — запросите демо у вашего менеджера': 'Протестируйте бесплатно — запросите демо у вашего менеджера',
    TRY_RFM_SEGMENTS: 'Попробовать',
    REGULAR_SEGMENTS: 'Простые',
    'RFM сегменты': 'RFM сегменты',
    'Автоматический расчет сегментов по покупательскому поведению.': 'Автоматический расчет сегментов по покупательскому поведению.',
    'Про RFM в базе знаний': 'Про RFM в базе знаний',
    'Попробуйте изменить фильтры': 'Попробуйте изменить фильтры',
    'Недостаточно данных': 'Недостаточно данных',
    'MAXMA накапливает историю сегментов и скоро выведет график': 'MAXMA накапливает историю сегментов и скоро выведет график',
    'Загрузите данные клиентов, чтобы увидеть распределение по сегментам': 'Загрузите данные клиентов, чтобы увидеть распределение по сегментам',
    'Всего покупок (для RFM)': 'Всего покупок (для RFM)',
    '(Выделить все)': '(Выделить все)',
    'Не задано': 'Не задано',
    'Покупают часто и много': 'Покупают часто и много',
    'Последняя покупка была недавно': 'Последняя покупка была недавно',
    'Покупали часто и много': 'Покупали часто и много',
    'Последняя покупка была очень давно': 'Последняя покупка была очень давно',
    'Последняя покупка была давно': 'Последняя покупка была давно',
    'Покупают довольно часто с небольшими или средними чеками': 'Покупают довольно часто с небольшими или средними чеками',
    'Покупают иногда с небольшими или средними чеками': 'Покупают иногда с небольшими или средними чеками',
    'Покупали довольно часто с небольшими или средними чеками': 'Покупали довольно часто с небольшими или средними чеками',
    'Сделали мало покупок': 'Сделали мало покупок',
    'Последняя покупка была слишком давно': 'Последняя покупка была слишком давно',
    Данные: 'Данные',
    'Всего строк': 'Всего строк',
    PURCHASES_RANGE: '{{amount}} $t(purchases_range, { "count": {{count}} })',
    purchases_range_one: 'покупки',
    purchases_range_few: 'покупок',
    purchases_range_many: 'покупок',
    'По всем клиентам': 'По всем клиентам',
    'Пожалуйста, установите начальную дату не ранее 8 июля 2023': 'Пожалуйста, установите начальную дату не ранее 8 июля 2023',
    RFM_CHART_SET_DATE: 'Установить',
    'Я хочу подключить модуль RFM': 'Я хочу подключить модуль RFM',
    'потрачено на покупку': 'потрачено на покупку',
    'отменено за возврат': 'отменено за возврат',
    'начислено оператором': 'начислено оператором',
    'списано оператором': 'списано оператором',
    'Причина блокировки': 'Причина блокировки',
    'Обновить уровни лояльности на основе суммы покупок': 'Обновить уровни лояльности на основе суммы покупок',
    'Номинал подарочной карты': 'Номинал подарочной карты',
    'Увеличить/уменьшить количество бонусов на указанное значение': 'Увеличить/уменьшить количество бонусов на указанное значение',
    'Задать новое количество бонусов': 'Задать новое количество бонусов',
    IMPORT_LINES: '{{amount}} $t(import_lines, { "count": {{count}} })',
    import_lines_one: 'строка',
    import_lines_few: 'строки',
    import_lines_many: 'строк',
    LINES: '$t(lines, { "count": {{count}} })',
    lines_one: 'строка',
    lines_few: 'строки',
    lines_many: 'строк',
    логин: 'логин',
    пароль: 'пароль',
    DAYS_MESSAGE: '$t(days_message, { "count": {{count}} })',
    days_message_one: 'день',
    days_message_few: 'дня',
    days_message_many: 'дней',
    'Скоро день рождения': 'Скоро день рождения',
    Скоро: 'Скоро',
    'Введите нули, если нужна текущая дата/сегодня': 'Введите нули, если нужна текущая дата/сегодня',
    BEFORE: 'до',
    AFTER: 'после',
    COUNTER_NAME_HELP: 'Дайте название счетчика, которое будет его отличать от счетчиков в других возможных акциях и выводиться на чеках клиента',
    COUNTER_APPLY_ON_PRODUCT_HELP: 'Вознаграждение будет выдаваться за N-й купленный <2>товар</2> или <2>набор товаров</2> — независимо от чека. Например за каждый 3-й товар купленный в нескольких чеках — товар в подарок',
    COUNTER_APPLY_ON_PURCHASE_HELP: 'Вознаграждение будет выдаваться за N-ю <2>покупку</2> с заданными характеристиками. Например за каждую 3-ю покупку суммой выше 2000 {{currency}} — товар в подарок',
    COUNTER_PERIOD_IN: 'за',
    IN_MONTHS: '$t(in_months, { "count": {{count}} })',
    in_months_one: 'месяц',
    in_months_few: 'месяца',
    in_months_many: 'месяцев',
    IN_DAYS: '$t(in_days, { "count": {{count}} })',
    in_days_one: 'день',
    in_days_few: 'дня',
    in_days_many: 'дней',
    COUNTER_FILTER_HELP: 'Для настройки акций N-й товар или покупка в подарок. <2>Инструкции по настройке</2>',
    COUNTER_RESET_HELP_DAY: 'Незаполненный за {{count}} $t(in_days, { "count": {{count}} }) счетчик сбрасывается',
    COUNTER_RESET_HELP_MONTH: 'Незаполненный за {{count}} $t(in_months, { "count": {{count}} }) счетчик сбрасывается',
    COUNTER_REWARD_HELP_PURCHASE: 'Вознаграждение будет выдаваться за {{count}}-ю покупку.',
    COUNTER_REWARD_HELP_PRODUCT: 'Вознаграждение будет выдаваться за {{count}}-й товар.',
    PRODUCT_RANGE: '$t(product_range, { "count": {{count}} })',
    product_range_one: 'товара',
    product_range_few: 'товаров',
    product_range_many: 'товаров',
    PURCHASE_RANGE: '$t(purchase_range, { "count": {{count}} })',
    purchase_range_one: 'покупки',
    purchase_range_few: 'покупок',
    purchase_range_many: 'покупок',
    COPY_WALLET_CARD: 'Скопировать дизайн\nкарты {{name}}',
    WALLET_CARD_MESSAGE_EXAMPLE: 'Виктория, поздравляем! Ваш подарок: 500 бонусов на покупки, до 31.12',
    'Карты, уже установленные у клиентов, получат обновление в течение суток': 'Карты, уже установленные у клиентов,\nполучат обновление в течение суток',
    DATE_FORMAT_LABEL: 'Формат отображения',
    TRIGGER_TYPE_SEGMENT_JOIN: 'Попадание в сегмент',
    TRIGGER_TYPE_SEGMENT_LEAVE: 'Покидание сегмента',
    TRIGGER_TYPE_PURCHASE: 'Совершение покупки',
    TRIGGER_TYPE_BONUS_ACTIVATE: 'Активация бонусов',
    TRIGGER_TYPE_RETURN: 'Возврат',
    TRIGGER_TYPE_ABANDONED_CART: 'Уход с сайта (брошенная корзина)',
    TRIGGER_TYPE_PRODUCT_VIEW: 'Уход с сайта (брошенный просмотр товара или категории)',
    TRIGGER_TYPE_PRICE_IN_CART_DROPPED: 'Снижение цены на товар в корзине',
    TRIGGER_TYPE_PRICE_IN_FAVORITE_DROPPED: 'Снижение цены на товар в избранном',
    TRIGGER_TYPE_BIRTHDAY: 'День рождения',
    TRIGGER_TYPE_CHILD_BIRTHDAY: 'День рождения ребенка',
    TRIGGER_TYPE_BONUS_EXPIRING: 'Сгорание бонусов',
    TRIGGER_TYPE_REGISTER_CLIENT: 'Регистрация в ПЛ',
    TRIGGER_TYPE_REGISTER_WALLET_CARD: 'Установка карты Wallet',
    TRIGGER_TYPE_SUBSCRIBE_EMAIL: 'Подписка на Email-рассылку',
    TRIGGER_TYPE_SCHEDULE: 'По расписанию',
    TRIGGER_TYPE_LEVEL_UP: 'Достижение нового уровня',
    TRIGGER_TYPE_EMAIL_DELIVERED: 'Получение Email-рассылки',
    FLOWS_PANEL_HEADER: '{{amount}} $t(flows, { "count": {{count}} })',
    flows_one: 'цепочка',
    flows_few: 'цепочки',
    flows_many: 'цепочек',
    DELETE_MAILING_DESCRIPTION: 'Рассылка будет удалена и из Автоматических рассылок,\nи из Цепочек',
    MAILING_GOAL_DAYS_TO: '$t(mailing_goal_days_to, { "count": {{count}} })',
    mailing_goal_days_to_one: 'дня с момента рассылки',
    mailing_goal_days_to_few: 'дней с момента рассылки',
    mailing_goal_days_to_many: 'дней с момента рассылки',
    LOGIN_2FA_DESCRIPTION: 'Мы отправили код безопасности на почту {{ email }}, так как вы зашли с нового устройства.',
    // События пользователей страницы clients/events
    SENDING_CLICK: 'Кликнул по <a href="{{linkHref}}" target="_blank">ссылке</a> в email <a href="{{emailHref}}" target="_blank">{{emailName}}</a>',
    SENDING_VIBER: 'Отправлен Viber <a href="{{linkHref}}" target="_blank">{{mailingName}}</a>',
    SENDING_SMS: 'Отправлен SMS <a href="{{linkHref}}" target="_blank">{{mailingName}}</a>',
    SENDING_EMAIL: 'Отправлен email <a href="{{linkHref}}" target="_blank">{{mailingName}}</a>',
    PURCHASE_RETURN: 'Сделал <a href="{{linkHref}}" target="_blank">возврат</a>',
    FRIEND_PURCHASE: 'Друг сделал <a href="{{linkHref}}" target="_blank">покупку</a>',
    PURCHASE_REVERT: 'Отмена <a href="{{linkHref}}" target="_blank">покупки</a>',
    ACCRUED_TO_EMPLOYEES: 'Начислено сотрудником:\n{{operatorName}}',
    WRITTEN_OFF_BY_EMPLOYEE: 'Списано сотрудником:\n{{operatorName}}',
    DELETED_BY_EMPLOYEE: 'Удален сотрудником:\n{{operatorName}}',
    BLOCKED_BY_EMPLOYEE: 'Заблокирован сотрудником:\n{{operatorName}}',
    UNBLOCKED_BY_EMPLOYEE: 'Разблокирован сотрудником:\n{{operatorName}}',
    UPDATED_BY_EMPLOYEE: 'Изменены сотрудником:\n{{operatorName}}',
    CREATED_BY_EMPLOYEE: 'Создан сотрудником:\n{{operatorName}}',
    FRIENDS_BONUS: 'Друг: {{referralPurchaseClientName}}',
    LEAVE_SEGMENT: 'Вышел из {{segmentName}}',
    LEVEL_UP: 'Перешел на уровень {{level}}',
    CREATED_WALLET_CARD: 'Установил карту Wallet, уровень Х, {{family}}',
    DELETED_WALLET_CARD: 'Удалил карту Wallet, {{family}}',
    'Создал заказ': 'Создал заказ',
    'Собрано на покупку': 'Собрано на покупку',
    'Применено на покупку': 'Применено на покупку',
    'Счётчик предложений увеличен': 'Счётчик предложений увеличен',
    'Счётчик предложений истёк': 'Счётчик предложений истёк',
    'Выдан купон на предложение': 'Выдан купон на предложение',
    'Срок действия истек': 'Срок действия истек',
    'Бонусы отменены': 'Бонусы отменены',
    'Начислены бонусы': 'Начислены бонусы',
    'Отозваны бонусы': 'Отозваны бонусы',
    'Восстановлены бонусы': 'Восстановлены бонусы',
    'Отменены бонусы за отменённую покупку': 'Отменены бонусы за отменённую покупку',
    'Возвращены применённые бонусы за отменённую покупку': 'Возвращены применённые бонусы за отменённую покупку',
    'Собрано вознаграждение за покупку друга': 'Собрано вознаграждение за покупку друга',
    'Присоединиться к сегменту': 'Присоединиться к сегменту',
    Создание: 'Создание',
    'Изменены данные': 'Изменены данные',
    'Изменены данные (клиентом)': 'Изменены данные (клиентом)',
    Удаление: 'Удаление',
    Разблокирован: 'Разблокирован',
    'Создание (клиентом)': 'Создание (клиентом)',
    'Удаление (клиентом)': 'Удаление (клиентом)',
    Другое: 'Другое',
    'Содержащиеся точки останутся в системе вне групп': 'Содержащиеся точки останутся\nв системе вне групп',
    SHOPS_PLURAL: '{{amount}} $t(shops_plural, { "count": {{count}} })',
    shops_plural_one: 'точка',
    shops_plural_few: 'точки',
    shops_plural_many: 'точек',
    GROUPS_PLURAL: '{{amount}} $t(groups_plural, { "count": {{count}} })',
    groups_plural_one: 'группа',
    groups_plural_few: 'группы',
    groups_plural_many: 'групп',
};
