import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { CloseCircle } from '@/icons/CloseCircle';
import { Filters } from './Filters';
import styles from './styles.module.scss';
export const FiltersInput = memo(({ filtersTree, value, onUpdate, customBadges = [], onRemoveCustomBadge, buttonTheme = 'primary', hasError = false, hasResetButton = true, hasOperators = true, hasHangingOperator = true, shouldDisplayActions = true, shouldDisplayFilterBadges = true, buttonRef, rightPanelClassName, selectedCode, }) => {
    const { t } = useTranslation();
    const handleResetFilters = () => {
        onUpdate([]);
        if (onRemoveCustomBadge)
            onRemoveCustomBadge();
    };
    const resetButton = hasResetButton ? (_jsx(Tooltip, Object.assign({ title: t('Сбросить фильтры'), placement: 'top' }, { children: _jsx("button", Object.assign({ type: 'button', className: styles.resetButton, onClick: handleResetFilters }, { children: _jsx(CloseCircle, {}) })) }))) : null;
    return (_jsx(Filters, { className: styles.filtersInput, filtersTree: filtersTree, value: value, onUpdate: onUpdate, customBadges: customBadges, onRemoveCustomBadge: onRemoveCustomBadge, actions: resetButton, buttonTheme: buttonTheme, shouldHideIfEmpty: false, hasError: hasError, hasOperators: hasOperators, hasHangingOperator: hasHangingOperator, shouldDisplayActions: shouldDisplayActions, shouldDisplayFilterBadges: shouldDisplayFilterBadges, buttonRef: buttonRef, rightPanelClassName: rightPanelClassName, selectedCode: selectedCode }));
});
