import { maxmaApi } from '@/api';
export function getShopListApi() {
    return maxmaApi.get('/shop/list');
}
export function getShopOptionsApi() {
    return maxmaApi.get('/shop/options-list');
}
export function getShopGroupListApi() {
    return maxmaApi.get('/shop/group/list');
}
