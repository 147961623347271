import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { getShopGroupOptionsSelector } from '@/store/selectors/filters';
import { getShopGroupListThunk, getShopListThunk } from '@/store/thunk/filters';
export const useUserShopFilters = (customItems = [], savedFilters = []) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const shopGroupsTree = useAppSelector(getShopGroupOptionsSelector(savedFilters, t));
    const defaultItems = [
        {
            type: 'leaf',
            id: 'shop',
            label: t('Точки продаж'),
            template: 'checkboxes-tree-loader',
            loadTree: () => {
                dispatch(getShopListThunk());
                dispatch(getShopGroupListThunk());
            },
            loadBranch: noop,
            options: shopGroupsTree,
            branchPrefix: 'Group',
            leafPrefix: '',
            hideTitle: true,
        },
    ];
    const tree = [...customItems, ...defaultItems];
    return tree;
};
const noop = () => { };
